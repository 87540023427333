import React, { useState, useEffect } from "react";
import { Alert } from "react-bootstrap";

function UserNotRecognizedAlertBox() {
  return (
    <div>
      <Alert variant="danger" className="p-4">
        <i className="fa fa-exclamation-circle fa-fw" />
        Please contact your Admin to make sure they have added your email in
        Yieldbooster.
      </Alert>
    </div>
  );
}

export default UserNotRecognizedAlertBox;
