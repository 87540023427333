import React from "react";
import { Button } from "react-bootstrap";
import ReactTooltip from "react-tooltip";

class FilterInventoryPopover extends React.PureComponent {
  constructor(props) {
    super(props);

    const {
      isReseller,
      publishers,
      selectedPubId,
      networks,
      selectedNetworkId,
      selectedYieldSetId,
    } = props;

    // filter networks
    let networkOptions = [];
    if (selectedPubId !== -1) {
      networkOptions = _.filter(this.props.networks, {
        pubId: selectedPubId,
      });
    }

    // filter yieldsets
    let yieldSetOptions = [];
    if (selectedNetworkId !== -1) {
      yieldSetOptions = _.filter(this.props.yieldSets, {
        gamNetworkId: selectedNetworkId,
      });
    }

    this.state = {
      isReseller,
      publisherOptions: publishers,
      selectedPubId,

      networkOptions: networks,
      selectedNetworkId,
      yieldSetOptions,
      selectedYieldSetId,
    };

    this.handlePublisherSelected = this.handlePublisherSelected.bind(this);
    this.handleNetworkSelected = this.handleNetworkSelected.bind(this);
    this.handleYieldSetSelected = this.handleYieldSetSelected.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleApplyFilter = this.handleApplyFilter.bind(this);
  }

  handlePublisherSelected(pubId) {
    if (pubId === -1) {
      this.setState({
        selectedPubId: pubId,
        selectedNetworkId: -1,
        selectedYieldSetId: -1,
      });
    } else {
      // filter networks
      const filteredNetworks = _.filter(this.props.networks, {
        pubId,
      });
      const networkOptions = filteredNetworks;
      let selectedNetworkId = -1;
      // if only one network, auto select that network
      if (networkOptions.length === 1) {
        selectedNetworkId = networkOptions[0].id;
      }

      if (selectedNetworkId !== -1) {
        // filter yieldsets
        const filteredYieldSets = _.filter(this.props.yieldSets, {
          gamNetworkId: selectedNetworkId,
        });
        const yieldSetOptions = filteredYieldSets;
        this.setState({
          yieldSetOptions,
        });
      }

      this.setState({
        selectedPubId: pubId,
        networkOptions,
        selectedNetworkId,
        selectedYieldSetId: -1,
      });
    }
  }

  handleNetworkSelected(networkId) {
    if (networkId === -1) {
      this.setState({
        selectedNetworkId: networkId,
        selectedYieldSetId: -1,
      });
    } else {
      // filter yieldsets
      const filteredYieldSets = _.filter(this.props.yieldSets, {
        gamNetworkId: networkId,
      });
      const yieldSetOptions = filteredYieldSets;
      this.setState({
        selectedNetworkId: networkId,
        selectedYieldSetId: -1,
        yieldSetOptions,
      });
    }
  }

  handleYieldSetSelected(ysId) {
    this.setState({
      selectedYieldSetId: ysId,
    });
  }

  handleApplyFilter() {
    const { selectedPubId, selectedNetworkId, selectedYieldSetId } = this.state;
    this.props.handleInventoryFilterChanged({
      selectedPubId,
      selectedNetworkId,
      selectedYieldSetId,
    });
    this.props.handleClosePopover();
  }

  handleCancel() {
    // close and nothing happened
    this.props.handleClosePopover();
  }

  render() {
    const {
      isReseller,
      publisherOptions,
      selectedPubId,

      networkOptions,
      selectedNetworkId,
      yieldSetOptions,
      selectedYieldSetId,
    } = this.state;

    return (
      <React.Fragment>
        {isReseller && (
          <div style={{ marginBottom: "8px" }}>
            <div>Publishers:</div>

            <div className="d-flex">
              <div className="border-right pr-3">
                <Button
                  variant="light"
                  size="sm"
                  style={styles.allBtn}
                  active={selectedPubId === -1}
                  onClick={() => this.handlePublisherSelected(-1)}
                >
                  All
                </Button>
              </div>
              <div className="pl-3" style={styles.listSection}>
                {publisherOptions.map((n) => {
                  return (
                    <div
                      key={n.id}
                      className="d-inline"
                      data-tip
                      data-for={`pubfilter-${n.id}`}
                    >
                      <Button
                        variant="light"
                        style={styles.listBtn}
                        size="sm"
                        disabled={!n.hasPerfData}
                        active={selectedPubId === n.id}
                        onClick={() => this.handlePublisherSelected(n.id)}
                      >
                        {n.name}
                      </Button>

                      {!n.hasPerfData && (
                        <ReactTooltip
                          id={`pubfilter-${n.id}`}
                          type="dark"
                          effect="solid"
                          place="top"
                        >
                          Has no reporting data
                        </ReactTooltip>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        )}

        {selectedPubId !== -1 && (
          <div style={{ marginBottom: "8px" }}>
            <div>Networks:</div>

            <div className="d-flex">
              <div className="border-right pr-3">
                <Button
                  variant="light"
                  size="sm"
                  style={styles.allBtn}
                  active={selectedNetworkId === -1}
                  onClick={() => this.handleNetworkSelected(-1)}
                >
                  All
                </Button>
              </div>
              <div className="pl-3" style={styles.listSection}>
                {networkOptions.map((n) => {
                  return (
                    <div
                      key={n.id}
                      className="d-inline"
                      data-tip
                      data-for={`networkfilter-${n.id}`}
                    >
                      <Button
                        variant="light"
                        style={styles.listBtn}
                        size="sm"
                        disabled={!n.hasPerfData}
                        active={selectedNetworkId === n.id}
                        onClick={() => this.handleNetworkSelected(n.id)}
                      >
                        {n.name}
                      </Button>

                      {!n.hasPerfData && (
                        <ReactTooltip
                          id={`networkfilter-${n.id}`}
                          type="dark"
                          effect="solid"
                          place="top"
                        >
                          Has no reporting data
                        </ReactTooltip>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        )}

        <div>
          {selectedNetworkId !== -1 && (
            <div>
              <div>Yield Sets:</div>

              <div className="d-flex">
                <div className="border-right pr-3">
                  <Button
                    variant="light"
                    size="sm"
                    style={styles.allBtn}
                    active={selectedYieldSetId === -1}
                    onClick={() => this.handleYieldSetSelected(-1)}
                  >
                    All
                  </Button>
                </div>
                <div className="pl-3" style={styles.listSection}>
                  {yieldSetOptions.map((ys) => {
                    return (
                      <div
                        key={ys.id}
                        className="d-inline"
                        data-tip
                        data-for={`ysfilter-${ys.id}`}
                      >
                        <Button
                          variant="light"
                          style={styles.listBtn}
                          size="sm"
                          data-tip
                          data-for={`ysfilter-${ys.id}`}
                          active={selectedYieldSetId === ys.id}
                          disabled={!ys.hasPerfData}
                          onClick={() => this.handleYieldSetSelected(ys.id)}
                        >
                          {ys.name}
                        </Button>

                        {!ys.hasPerfData && (
                          <ReactTooltip
                            id={`ysfilter-${ys.id}`}
                            type="dark"
                            effect="solid"
                            place="top"
                          >
                            Has no reporting data
                          </ReactTooltip>
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          )}
        </div>

        <div className="pt-3 mt-3 border-top d-flex justify-content-end">
          <Button variant="link" className="px-4" onClick={this.handleCancel}>
            Cancel
          </Button>

          <Button
            variant="primary"
            className="px-4"
            onClick={this.handleApplyFilter}
          >
            Apply
          </Button>
        </div>
      </React.Fragment>
    );
  }
}

const styles = {
  allBtn: {
    width: "72px",
    border: "1px solid #D1D5DB",
    color: "#374151",
    marginRight: "4px",
    marginBottom: "4px",
  },
  listSection: {
    maxHeight: "164px",
    overflowY: "auto",
  },
  listBtn: {
    border: "1px solid #D1D5DB",
    color: "#374151",
    marginRight: "4px",
    marginBottom: "4px",
  },
};

export default FilterInventoryPopover;
