import React from "react";
import { Query, Mutation } from "react-apollo";

import CommonStyle from "common/styles/common.css";
import FormStyle from "common/styles/forms.css";
import YbLoadingView from "common/components/yb-loading-view";
import YbLoading from "common/components/yb-loading";
import YbModal from "common/components/yb-modal";
import YbHighlight from "common/components/yb-highlight";
import YbTreeSelector from "common/components/yb-tree-selector";
import { ACCOUNT_CONFIG } from "common/query/publisher";

import Styles from "../styles/wizard.css";

import YbTracker from "../components/yb-tracker";
import YbWizardStep1 from "../components/yb-wizard-step1";
import YbWizardStep2 from "../components/yb-wizard-step2";
import YbWizardStep3 from "../components/yb-wizard-step3";
import YbWizardStep4 from "../components/yb-wizard-step4-v4";
// import YbWizardStep4 from "../components/yb-wizard-step4-v2";
// import YbWizardStep5 from "../components/yb-wizard-step5";
import YbWizardStep6 from "../components/yb-wizard-step6";

const overviewRoute = "/summary/overview";

class Wizard extends React.Component {
  constructor(props) {
    super(props);

    this.backToSummary = this.backToSummary.bind(this);
    this.goToSummary = this.goToSummary.bind(this);
    this.handleOptionChange = this.handleOptionChange.bind(this);
    this.showNextStep = this.showNextStep.bind(this);
    this.showExit = this.showExit.bind(this);
    this.nextStep = this.nextStep.bind(this);
    this.previousStep = this.previousStep.bind(this);
    this.onExitModalHide = this.onExitModalHide.bind(this);

    this.openModal = this.openModal.bind(this);
    this.updateCurrentData = this.updateCurrentData.bind(this);
    this.onHideModal = this.onHideModal.bind(this);
    this.resetStep = this.resetStep.bind(this);
    this.onClickExit = this.onClickExit.bind(this);
    this.setStep = this.setStep.bind(this);
    this.stepDescription = this.stepDescription.bind(this);

    // workaround for step 4 functions
    this.renderPrevStepBtn = this.renderPrevStepBtn.bind(this);
    this.renderExitBtn = this.renderExitBtn.bind(this);
    this.nextStepFnForStep4 = this.nextStepFnForStep4.bind(this);

    const pubId = _.get(this.props, "match.params.publisherId");

    this.state = {
      currentStep: 1,
      totalSteps: 5,
      stepData: {},
      showExitModal: false,
      crossedStep4: false,
      publisherId: _.parseInt(pubId),
    };

    for (
      var index = this.state.currentStep;
      index <= this.state.totalSteps;
      index++
    ) {
      this.state.stepData[index] = {};
    }

    this.resetStep(1);
  }

  componentDidMount() {
    window.gtagPage(`/wizard-step-${this.state.currentStep}`);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.currentStep !== this.state.currentStep) {
      window.gtagPage(`/wizard-step-${this.state.currentStep}`);
    }
  }

  // shouldComponentUpdate(nextProps, nextState) {
  //   if (this.state.currentStep === 4) {
  //     return false;
  //   } else {
  //     return true;
  //   }
  // }

  onExitModalHide() {
    this.setState({
      showExitModal: false,
    });
  }

  setStep(step) {
    var crossedStep4 = this.state.crossedStep4;
    var currentStep = this.state.currentStep;
    var stepData = this.state.stepData[currentStep];

    if (crossedStep4 && step < 5) {
      return;
    }

    if (stepData.isLoading) {
      return;
    }

    this.setState({
      currentStep: step,
    });
  }

  openModal() {
    this.updateCurrentData((stepData) => {
      stepData.showInfoModal = true;
      stepData.modalContent = <div />;
    });
  }

  onClickExit() {
    this.setState({
      showExitModal: true,
    });
  }

  onHideModal() {
    this.updateCurrentData((stepData) => {
      stepData.showInfoModal = false;
    });
  }

  goToSummary(accountId, networkId, yieldSetId) {
    if (this.state.publisherId > 0) {
      this.props.history.push(
        `/publisher/${this.state.publisherId}${overviewRoute}/${accountId}/${networkId}/${yieldSetId}`
      );
    } else {
      this.props.history.push(
        `${overviewRoute}/${accountId}/${networkId}/${yieldSetId}`
      );
    }
  }

  backToSummary(event) {
    this.props.history.goBack();

    this.setState({
      showExitModal: false,
    });

    if (event) {
      event.preventDefault();
    }
  }

  updateCurrentData(process) {
    var currentStep = this.state.currentStep;
    var stepData = this.state.stepData;
    var currentStepData = this.state.stepData[currentStep];

    process(currentStepData, currentStep, stepData);

    this.setState({
      stepData: stepData,
    });
  }

  showExit() {
    var currentStep = this.state.currentStep;
    var stepData = this.state.stepData[currentStep];

    switch (currentStep) {
      case 4:
        return !stepData.isLoading;
      case 5:
      case 6:
        return false;
    }

    return true;
  }

  nextStep(event) {
    var currentStep = this.state.currentStep;
    var stepData = this.state.stepData;
    var crossedStep4 = this.state.crossedStep4;
    var previousStepData = stepData[currentStep];
    var nextStepData = stepData[++currentStep];
    var mutationParams = null;

    switch (currentStep) {
      case 2:
        nextStepData.selectedAccount = previousStepData.selectedAccount;
        break;
      case 3:
        nextStepData.nextStepFunc = this.nextStep;
        nextStepData.selectedNetwork = previousStepData.selectedNetwork;
        nextStepData.selectedAccount = previousStepData.selectedAccount;
        break;
      case 4:
        previousStepData.forwarding = false;
        nextStepData.selectedNetwork = previousStepData.selectedNetwork;
        nextStepData.selectedYieldSet = previousStepData.selectedYieldSet;
        nextStepData.selectedAccount = previousStepData.selectedAccount;
        nextStepData.items = previousStepData.items;
        crossedStep4 = true;
        break;
      // case 5:
      //   nextStepData.allocationCtrl = {};
      //   nextStepData.verifiedNewAdUnits = false;
      //   nextStepData.sizesWithNone = [];
      //   nextStepData.unsaved = false;
      //   nextStepData.selectedYieldSet = previousStepData.selectedYieldSet;
      //   nextStepData.selectedAccount = previousStepData.selectedAccount;
      //   nextStepData.selectedNetwork = previousStepData.selectedNetwork;
      //   nextStepData.items = previousStepData.items;
      //   nextStepData.clean = true;
      //   nextStepData.showAlert = true;
      //   crossedStep4 = true;
      //   break;
      case 5:
        nextStepData.showWhatsNext = false;
        nextStepData.items = previousStepData.items;
        nextStepData.selectedYieldSet = previousStepData.selectedYieldSet;
        nextStepData.selectedNetwork = previousStepData.selectedNetwork;
        nextStepData.selectedAccount = previousStepData.selectedAccount;
        break;
      case 6:
        setTimeout(() => {
          this.goToSummary(
            previousStepData.selectedAccount.accountId,
            previousStepData.selectedNetwork.networkId,
            previousStepData.selectedYieldSet.yieldSetId
          );
        });
        return;
    }

    this.setState({
      currentStep: currentStep,
      stepData: stepData,
      crossedStep4: crossedStep4,
    });

    if (event) {
      event.preventDefault();
    }
  }

  nextStepFnForStep4(selectedItems) {
    var currentStep = 4;
    var stepData = this.state.stepData;
    var previousStepData = stepData[currentStep];
    var nextStepData = stepData[++currentStep];

    previousStepData.forwarding = false;

    nextStepData.selectedNetwork = previousStepData.selectedNetwork;
    nextStepData.selectedYieldSet = previousStepData.selectedYieldSet;
    nextStepData.selectedAccount = previousStepData.selectedAccount;

    nextStepData.items = selectedItems;

    setTimeout(() => {
      this.setState({
        currentStep: currentStep,
        stepData: stepData,
        crossedStep4: true,
      });
    });
  }

  resetStep(step, noQuery) {
    var stepData = this.state.stepData[step];

    switch (step) {
      case 1:
        stepData.selectedAccount = null;
        stepData.nextStepFunc = this.nextStep;
        this.resetStep(2);
        break;
      case 2:
        if (!noQuery) {
          stepData.networks = null;
        }

        stepData.selectedNetwork = null;
        stepData.verifiedGAMSettings = stepData.radio !== "option1";
        stepData.showPermissionDeniedAlertNetwork = null;
        this.resetStep(3);
        break;
      case 3:
        if (!noQuery) {
          stepData.yieldSets = null;
        }

        stepData.name = "";
        stepData.selectedYieldSet = null;
        stepData.adxAccount = "";
        stepData.adSenseAccount = "";
        stepData.addAdSense = false;
        stepData.addAdx = false;
        stepData.showAdx = false;
        stepData.reloadAdsense = true;
        stepData.reloadAdx = true;
        stepData.showAdSense = false;
        stepData.showAdSenseComponent = false;
        stepData.forwarding = false;
        this.resetStep(4);
        break;
      case 4:
        if (!noQuery) {
          stepData.candidates = null;
        }

        stepData.items = [];
        break;
      // case 5:
      //   stepData.verifiedNewAdUnits = false;
      //   stepData.showAlert = true;
      //   stepData.showAdSenseTutorial = false;
      //   stepData.unsaved = false;
      //   stepData.clean = true;
      //   stepData.sizesWithNone = [];
      //   stepData.allocationCtrl = {};
      //   break;
      case 5:
        stepData.showFinishModal = false;
        break;
    }
  }

  handleOptionChange(event) {
    this.updateCurrentData((stepData, currentStep) => {
      stepData.radio = event.target.value;
      this.resetStep(currentStep, true);
    });
  }

  showNextStep(currentStep) {
    var totalSteps = this.state.totalSteps;
    var stepData = this.state.stepData[currentStep];

    switch (currentStep) {
      case 1:
        return !!stepData.selectedAccount;
      case 2:
        return !!stepData.selectedNetwork;
      case 3:
        return !!(
          stepData.selectedYieldSet ||
          (stepData.radio === "option1" && stepData.showCreate)
        );
      case 4:
        // return true;
        return (
          !stepData.reloadExternalSource &&
          stepData.items &&
          !!stepData.items.length
        );
      // case 5:
      //   if (
      //     stepData.selectedYieldSet &&
      //     !stepData.selectedYieldSet.adsenseAccount
      //   ) {
      //     return true;
      //   }

      //   return !stepData.resetComponent && stepData.canContinue;
    }

    return false;
  }

  stepDescription(currentStep) {
    var stepData = this.state.stepData[currentStep];

    switch (currentStep) {
      case 1:
        return (
          <span>{i18n`SET_UP_A_GAM_PARENTHESIS_GOOGLE_AD_MANAGER_ACCOUNT`}</span>
        );
      case 2:
        if (stepData.selectedAccount) {
          return (
            <span>
              {i18n`SETUP_A_GAM_NETWORK_FOR_ACCOUNT_NAME`.replace(
                /\#ACCOUNT_NAME\#/,
                stepData.selectedAccount.name
              )}
            </span>
          );
        }

        return <span>{i18n`Setup a GAM Network`}</span>;
      case 3:
        if (stepData.selectedNetwork) {
          return (
            <span>
              {i18n`Set up a Yield Set for #NETWORK_NAME#`.replace(
                /\#NETWORK_NAME\#/,
                stepData.selectedNetwork.name
              )}
            </span>
          );
        }

        return <span>{i18n`Set up a Yield Set`}</span>;
      case 4:
        if (stepData.selectedNetwork) {
          return (
            <span>
              {i18n`Set up Ad Units for #YIELD_SET_NAME#`.replace(
                /\#YIELD_SET_NAME\#/,
                stepData.selectedYieldSet.name
              )}
            </span>
          );
        }

        return <span>{i18n`Set up Ad Units`}</span>;
      // case 5:
      //   return <span>{i18n`Allocate AdSense ad unit`}</span>;
      case 5:
        return (
          <span>{i18n`Launch Yieldbooster® and start earning more revenue!`}</span>
        );
      default:
        return <span />;
    }
  }

  renderStep(accountConfig) {
    var currentStep = this.state.currentStep;
    var stepData = this.state.stepData[currentStep];

    switch (currentStep) {
      case 1:
        return (
          <YbWizardStep1
            publisherId={this.state.publisherId}
            stepData={stepData}
            updateCurrentData={this.updateCurrentData}
            resetStep={this.resetStep}
            handleOptionChange={this.handleOptionChange}
            accountConfig={accountConfig}
          />
        );
      case 2:
        return (
          <YbWizardStep2
            publisherId={this.state.publisherId}
            stepData={stepData}
            updateCurrentData={this.updateCurrentData}
            resetStep={this.resetStep}
            handleOptionChange={this.handleOptionChange}
            accountConfig={accountConfig}
          />
        );

      case 3:
        return (
          <YbWizardStep3
            publisherId={this.state.publisherId}
            stepData={stepData}
            updateCurrentData={this.updateCurrentData}
            resetStep={this.resetStep}
            handleOptionChange={this.handleOptionChange}
            accountConfig={accountConfig}
          />
        );

      case 4:
        return (
          <YbWizardStep4
            publisherId={this.state.publisherId}
            stepData={stepData}
            updateCurrentData={this.updateCurrentData}
            resetStep={this.resetStep}
            handleOptionChange={this.handleOptionChange}
            accountConfig={accountConfig}
            renderPrevStepBtn={this.renderPrevStepBtn}
            renderExitBtn={this.renderExitBtn}
            nextStepFnForStep4={this.nextStepFnForStep4}
          />
        );

      // case 5:
      //   return (
      //     <YbWizardStep5
      //       publisherId={this.state.publisherId}
      //       stepData={stepData}
      //       updateCurrentData={this.updateCurrentData}
      //       resetStep={this.resetStep}
      //       handleOptionChange={this.handleOptionChange}
      //       accountConfig={accountConfig}
      //     />
      //   );

      case 5:
        return (
          <YbWizardStep6
            publisherId={this.state.publisherId}
            stepData={stepData}
            updateCurrentData={this.updateCurrentData}
            resetStep={this.resetStep}
            handleOptionChange={this.handleOptionChange}
            accountConfig={accountConfig}
          />
        );
      default:
        return <YbLoading />;
    }
  }

  showPreviousStep() {
    var currentStep = this.state.currentStep;
    var stepData = this.state.stepData[currentStep];

    switch (currentStep) {
      case 1:
        // case 5:
        return false;
      case 2:
      case 3:
        return true;
      // case 6:
      case 5:
        return false;
      case 4:
        return !stepData.isLoading;
    }

    return false;
  }

  previousStep() {
    var currentStep = this.state.currentStep;

    this.setState({
      currentStep: --currentStep,
    });

    event.preventDefault();
  }

  renderPrevStepBtn() {
    return (
      <button
        className={FormStyle.secondaryBtn}
        onClick={this.previousStep}
        // style={{ marginTop: "-5px" }} // to fix weird alignment, wtf
      >
        <span>
          <i className="fa fa-arrow-left" /> {i18n`PREVIOUS_STEP`}
        </span>
      </button>
    );
  }

  renderExitBtn() {
    return (
      <button
        className={FormStyle.cancelBtn}
        onClick={this.onClickExit}
        style={{ marginTop: "25px" }} // to fix weird alignment, wtf
      >
        <i className="fa fa-times" /> {i18n`Exit wizard`}
      </button>
    );
  }

  renderWizard(accountConfig) {
    var currentStep = this.state.currentStep;
    var totalSteps = this.state.totalSteps;
    var stepData = this.state.stepData;
    var crossedStep4 = this.state.crossedStep4;
    var currentStepData = stepData[currentStep];
    var nextStepBtn = (
      <button
        className={FormStyle.submitBtn}
        onClick={this.nextStep}
        // style={{ marginTop: "-5px" }} // to fix weird alignment, wtf
      >
        <span>
          <i className="fa fa-arrow-right" /> {i18n`NEXT_STEP`}
        </span>
      </button>
    );

    if (currentStepData.getNextStepBtn) {
      nextStepBtn = currentStepData.getNextStepBtn(this.nextStep);
    } else if (currentStep === totalSteps) {
      setTimeout(() => {
        this.forceUpdate();
      });
    }

    return (
      <div className={`${CommonStyle.wrapper}`}>
        <div className={CommonStyle.pageTitle}>
          {i18n`INVENTORY_ONBOARD_WIZARD`}
        </div>
        <br />
        <YbTracker
          currentStep={currentStep}
          stepData={stepData}
          showNextStep={this.showNextStep}
          setStep={this.setStep}
          stepDescription={this.stepDescription}
          crossedStep4={crossedStep4}
        />
        <div className={`${CommonStyle.textView} ${FormStyle.form}`}>
          <div>
            <b>{this.stepDescription(currentStep)}</b>
          </div>
          <br />
          {this.renderStep(accountConfig)}
          {this.showNextStep(currentStep) && nextStepBtn}
          {currentStep === totalSteps && nextStepBtn}

          {currentStep !== 4 &&
            this.showPreviousStep() &&
            this.renderPrevStepBtn()}
          {currentStep !== 4 && this.showExit() && this.renderExitBtn()}
        </div>
        <YbModal
          show={!!currentStepData.showInfoModal}
          onHide={this.onHideModal.bind(this)}
          title={i18n`Help`}
          noSuccessBtn={true}
          cancelText={i18n`CLOSE`}
        >
          {currentStepData.modalContent}
        </YbModal>
      </div>
    );
  }

  render() {
    var accountConfig = null;
    var showExitModal = this.state.showExitModal;

    window.stepData = this.state.stepData;

    return (
      <div>
        <Query
          query={ACCOUNT_CONFIG}
          variables={{ pubId: this.state.publisherId }}
          fetchPolicy="network-only"
        >
          {({ loading, error, data }) => {
            if (loading) {
              return <YbLoadingView />;
            }

            if (error) {
              return (
                <div className={`${CommonStyle.wrapper}`}>
                  <div
                    className={`${CommonStyle.textDanger} ${CommonStyle.textView}`}
                  >
                    {i18n`ERROR_LOADING_ACCOUNT_CONFIG_ELLIPSIS`}
                  </div>
                </div>
              );
            }

            if (data && !accountConfig) {
              accountConfig = data.accountConfig.config.resourceConfig;
            }

            return this.renderWizard(accountConfig);
          }}
        </Query>
        <YbModal
          show={showExitModal}
          title={i18n`Alert`}
          successText={i18n`Exit Wizard`}
          onHide={this.onExitModalHide}
          onSubmit={this.backToSummary}
          succesIcon="fa-times-circle-o"
        >
          <div className={CommonStyle.textAlignCenter}>
            <div>
              <b>{i18n`You are about to leave the Wizard?`}</b>
            </div>
            <br />
            <div>{i18n`Onboarded Items will remain in the Yieldbooster® for later operation`}</div>
          </div>
        </YbModal>
      </div>
    );
  }
}

module.exports = Wizard;
