import React from "react";
import { Query } from "react-apollo";
import YbLoadingView from "common/components/yb-loading-view";
import CommonStyles from "common/styles/common.css";
import _ from "lodash";

import { GET_BILLING_REPORT_URL } from "../query/payment-history";
import EmbeddedDashboard from "./../../dashboard/components/embedded-dashboard";

function BillingReportQuicksight() {
  return (
    <div>
      <Query query={GET_BILLING_REPORT_URL}>
        {({ loading, error, data }) => {
          if (loading) {
            return <YbLoadingView />;
          }

          if (error) {
            return (
              <div
                className={`${CommonStyles.textView} ${
                  CommonStyles.textDanger
                }`}
              >{i18n`ERROR_LOADING_DASHBOARD_ELLIPSIS`}</div>
            );
          }

          const url = _.get(data, "dashboard.billReportUrl");
          if (url) {
            return <EmbeddedDashboard dashboard={url} />;
          } else {
            return "Could not find the report you are looking for.";
          }
        }}
      </Query>
    </div>
  );
}

module.exports = BillingReportQuicksight;
