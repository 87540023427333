import React from "react";
import { withRouter } from "react-router-dom";

import Styles from "../styles/common.css";

// import YbScrollUp from "common/components/yb-scroll-up";

class YbFooter extends React.Component {
  constructor(props) {
    super(props);
  }

  languageChange(event) {
    this.props.languageChange(event.target.value);

    switch (this.props.history.location.pathname) {
      case "/":
        window.gtag("event", "language", {
          event_category: "welcomePage",
          event_label: event.target.value
        });
        break;
    }
  }

  render() {
    var selectedLanguage = this.props.language;
    var languages = this.props.languages;

    return (
      <div>
        <div className={Styles.divider} />
        <div className={Styles.footerWrapper}>
          <span className={Styles.footerLogo} />
          <div>
            <span>v{process.env.npm_package_version}</span>
            <span className={Styles.verticalDivider}>|</span>© 2014 -{" "}
            {new Date().getFullYear()} Intowow All Rights Reserved
            <span className={Styles.verticalDivider}>|</span>
            <a
              href="https://intowow.com/privacy/"
              onClick={() => {
                switch (this.props.history.location.pathname) {
                  case "/":
                    window.gtag("event", "privacyPolicy-footer", {
                      event_category: "welcomePage"
                    });
                    break;
                }
              }}
            >
              Privacy Policy
            </a>
            <span className={Styles.verticalDivider}>|</span>
            <span
              className={Styles.link}
              onClick={() => {
                this.props.history.push("/terms");
              }}
            >
              Service Agreement
            </span>
          </div>

          {false && (
            <div className={Styles.languageWrapper}>
              <select
                value={selectedLanguage}
                onChange={this.languageChange.bind(this)}
              >
                {languages.map((language, index) => {
                  return (
                    <option key={index} value={language.key}>
                      {language.name}
                    </option>
                  );
                })}
              </select>
            </div>
          )}
        </div>
        {/* <YbScrollUp pathname={this.props.history.location.pathname}/> */}
      </div>
    );
  }
}

module.exports = withRouter(YbFooter);
