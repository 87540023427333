import TrendGoogleRevenueDescription from "../images/trend-google-rev-description.jpg";

export const MetricDescription = {
  // Inventory Section
  TOTAL_UNITS: {
    title: "Total units",
    desc: "Number of ad units that are currently sending requests to your GAM.",
  },
  COMPATIBLE_UNITS: {
    title: "Compatible units",
    desc: "Number of ad units whose request types are mainly Google Publisher Tag and AMP Ad Tag that are compatible with Yieldbooster.",
  },
  ONBOARDED_UNITS: {
    title: "Onboarded units",
    desc: "Number of ad units that are onboarded to Yieldbooster platform.",
  },
  PROTECTED_UNITS: {
    title: "Protected units",
    desc: "Number of ad units that are activated for Yieldbooster protection.",
  },

  TRAFFIC_PROTECTED_SCORE: {
    title: "Traffic protected score",
    desc: "Score that represents how many of the compatible requests are protected by Yieldbooster against potential bid shading activities.",
    desc2: "Calculated as: (protected requests / compatible requests) * 100.",
  },

  // Algo Section
  CURRENT_SEGMENTS: {
    title: "Current segments",
    desc: "Number of current Yieldbooster segments that your protected units are clustered into. ",
    desc2:
      "The higher this number, the more finite traffic granularity it is required to counteract ongoing demand bid shading activities.",
  },
  FLOOR_STRATEGY_SIMULATIONS: {
    title: "Floor strategy simulations",
    desc: "Accumulated number of floor pricing strategy simulations that Yieldbooster has computed since day one. Each simulation produces the optimal floor pricing strategy to defend against buy-side bid shading activities for a given segment at a specific moment. ",
  },
  PRICING_DETECTIONS: {
    title: "Pricing detections",
    desc: "Accumulated number of floor pricing detections that Yieldbooster has operated since day one. Pricing detections produce important data points for Yieldbooster to simulate buyer behaviors at any specific moment. ",
    desc2:
      "With such detection, Yieldbooster is able to dynamically calculate the most suited traffic segmentation strategy for your inventory, and for each segment the optimal floor pricing strategy at any specific moment. ",
  },

  // Summary Section
  NET_INCREASED_GOOGLE_REV: {
    title: "Net increased revenue",
    desc: "Number of increased Google ad revenue that’s net of extra ad serving cost generated by Yieldbooster.",
  },
  PERCEIVED_REVENUE_UPLIFT: {
    title: "Perceived uplift",
    desc: "The percentage increase in Google revenue from onboarded units contributed by Yieldbooster optimization.",
    desc2:
      "Calculated as: (net increased Google revenue / original Google revenue).",
  },
  SECURED_LIFT: {
    title: "Secured Lift",
    desc: "The percentage increase in request RPM from defended requests contributed by Yieldbooster active defense against ongoing bid shading activities.",
    desc2:
      "Calculated as: [(secured request RPM - benchmark request RPM) / benchmark request RPM]",
  },

  COMPATIBLE_REQ: {
    title: "Compatible requests",
    desc: "Number of ad requests that are compatible with Yieldbooster.",
  },
  PROTECTED_REQ: {
    title: "Protected requests",
    desc: "Number of ad requests that are protected by Yieldbooster against potential bid shading activities. ",
  },
  DEFENDED_REQ: {
    title: "Defended requests",
    desc: "Number of requests actively defended by Yieldbooster against ongoing bid shading activities.",
  },

  PROTECTED_RATE: {
    title: "Protected rate",
    desc: "The percentage of compatible requests that are protected by Yieldbooster.",
    desc2: "Calculated as: (protected requests / compatible requests) ",
  },
  DEFENDED_RATE: {
    title: "Defended rate",
    desc: "The percentage of protected requests that are actively defended by Yieldbooster against ongoing bid shading activities.",
    desc2: "Calculated as: (defended requests / protected requests)",
  },

  YIELD_SET: {
    title: "Yield Set",
    desc: "A yield set is a set of ad units associated with your Google Ad Exchange demand account. All your onboarded ad units are grouped into a default yield set under your Network name.",
    desc2:
      "Yield set can also be used as a customized labeling feature to group your ad units. For example, if you have ad units spanning across multiple websites, you can group them by websites, with each yield set containning all ad units under the same website. This makes tracking Yieldbooster performance of your inventory easier on the dashboard.",
    desc3: "Contact your Solution Engineer for further support.",
  },

  // Trend Section
  TREND_GOOGLE_REVENUE: {
    title: "Google Revenue",
    img: TrendGoogleRevenueDescription,
    metrics: [
      {
        title: "Increased Revenue",
        desc: "Number of Google revenue that Yieldbooster has helped you secure from defending against ongoing bid shading activities, on top of the original google revenue.",
        symbolShape: "square", // square/circle/line
        symbolColor: "#FD9840", // orange
      },
      {
        title: "Original Revenue",
        desc: "Number of Google revenue that you would have originally earned from requests protected by Yieldbooster.",
        // desc2:
        //   "Calculated as: (unmanaged Google revenue + base Google revenue)",
        symbolShape: "square", // square/circle/line
        symbolColor: "#2F5BB0", // dark blue
      },
      {
        title: "Not-Protected Revenue",
        desc: "Number of Google revenue generated from requests that are not protected by Yieldbooster.",
        symbolShape: "square", // square/circle/line
        symbolColor: "#C6DBFF", // light blue
      },
      {
        title: "Extra Ad Serving Cost",
        desc: "Number of GAM ad serving cost that Yieldbooster has generated additionally during optimization.",
        symbolShape: "square", // square/circle/line
        symbolColor: "#C5C5C5", // light gray
      },
      {
        title: "Total Requests",
        desc: "Number of ad requests that are compatible with Yieldbooster.",
        symbolShape: "line", // square/circle/line
        symbolColor: "#3B3B3F", // black
      },
    ],
  },
  TREND_REQUEST_PROTECTION_INDEX: {
    title: "Request Protection Index",
    metrics: [
      {
        title: "Protected Score",
        desc: "Score that represents how many of the compatible requests are protected by Yieldbooster against potential bid shading activities.",
        desc2:
          "Calculated as: (protected requests / compatible requests) * 100.",
        symbolShape: "line", // square/circle/line
        symbolColor: "#3B3B3F", // black
      },
      {
        title: "Defended Score",
        desc: "Score that represents how many of the protected requests require active defense against ongoing bid shading activities.",
        desc2:
          "Higher score represents more vibrant buy-side bid shading activities toward your inventory.",
        symbolShape: "line", // square/circle/line
        symbolColor: "#345aef", // darkblue
      },
    ],
  },
  TREND_REQUEST_FUNNEL: {
    title: "Request Funnel",
    metrics: [
      {
        title: "Compatible Requests",
        desc: "Number of ad requests that are compatible with Yieldbooster.",
        symbolShape: "square", // square/circle/line
        symbolColor: "#C6DBFF", // light blue
      },
      {
        title: "Protected Requests",
        desc: "Number of ad requests that are protected by Yieldbooster against potential bid shading activities.",
        symbolShape: "square", // square/circle/line
        symbolColor: "#3C8FFC", // dark blue
      },
      {
        title: "Defended Requests",
        desc: "Number of requests actively defended by Yieldbooster against ongoing bid shading activities.",
        symbolShape: "square", // square/circle/line
        symbolColor: "#FD9840", // orange
      },
    ],
  },
  TREND_REVENUE_LIFT: {
    title: "Revenue Lift",
    metrics: [
      {
        title: "Secured Lift",
        desc: "The percentage increase in request RPM from defended requests contributed by Yieldbooster active defense against ongoing bid shading activities.",
        desc2:
          "Calculated as: [(secured request RPM - benchmark request RPM) / benchmark request RPM]",
        symbolShape: "line", // square/circle/line
        symbolColor: "#FD9840", // orange
      },
      {
        title: "Perceived Lift",
        desc: "The Google revenue percentage increase from protected requests contributed by Yieldbooster protection.",
        desc2:
          "Calculated as: (net increased Google revenue / original Google revenue).",
        symbolShape: "line", // square/circle/line
        symbolColor: "#E44949", // red
      },
    ],
  },
  TREND_SECURED_RRPM: {
    title: "Secured Google Request RPM",
    metrics: [
      {
        title: "Secured Request RPM",
        desc: "The Google ad request RPM from defended requests.",
        desc2:
          "Calculated as: [(Secured Google revenue * 1000) / defended requests].",
        symbolShape: "line", // square/circle/line
        symbolColor: "#FD9840", // orange
      },
      {
        title: "Benchamrk Request RPM",
        desc: "The Google ad request RPM from a small fraction of defended requests randomly allocated to the benchmark group for reliable simulation of your original traffic earning power in the absence of Yieldbooster protection.",
        desc2:
          "Calculated as: [(benchmark Google revenue * 1000) / benchmark requests].",
        symbolShape: "line", // square/circle/line
        symbolColor: "#3C8FFC", // dark blue
      },
    ],
  },
};
