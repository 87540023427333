import React from "react";
import { Container, Card, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import moment from "moment";
import MedalImage from "../../common/images/medal-image.png";

class SubscriptionDescription extends React.Component {
  constructor(props) {
    super(props);

    const billableStartDate = this.props.billableStartDate
      ? moment(this.props.billableStartDate).format("YYYY/MM/DD")
      : "";

    const nextPaymentDate = this.props.isSubscribed
      ? this.getNextPaymentDate(
          new Date(),
          new Date(this.props.billableStartDate)
        )
      : "";

    this.state = {
      // isFreeUser: this.props.isFreeUser, // should not have this for now
      isInFreeTrial: this.props.isInFreeTrial,
      isFreeTrialExpired: this.props.isFreeTrialExpired,
      isSubscribed: this.props.isSubscribed,
      isSubscribing: this.props.isSubscribing,
      isInactive: this.props.isInactive,
      isChargeFailed: this.props.isChargeFailed,

      subscribePath: "/subscription-and-billing/subscribe",

      // isInFreeTrial or isSubscribed but still in free trial
      trialRemainingDays: this.props.trialRemainingDays,
      // isSubscribed
      billableStartDate,
      nextPaymentDate
    };
  }

  getNextPaymentDate(date, billableStartDate) {
    let targetDate = date;
    if (billableStartDate && billableStartDate > date) {
      targetDate = billableStartDate;
    }

    return moment(targetDate).date() <= 6
      ? moment(targetDate)
          .date(6)
          .format("YYYY/MM/DD")
      : moment(targetDate)
          .add(1, "month")
          .date(6)
          .format("YYYY/MM/DD");
  }

  render() {
    let descriptionJsx = "";

    if (this.state.isInFreeTrial) {
      descriptionJsx = (
        <div>
          <Container>
            <Row>
              <Col md="5">
                <Card>
                  <Card.Body className="clearfix">
                    <div className="text-center">
                      <div style={{ marginBottom: "10px" }}>
                        <img src={MedalImage} width="80px" />
                      </div>
                      <div style={{ marginBottom: "20px" }}>
                        <h3>Yieldbooster®</h3>
                        <small style={{ color: "#414a52" }}>
                          Continue Yieldbooster® to keep increasing AdSense &
                          AdX revenue with no coding, no man hours, and no
                          headaches.
                        </small>
                      </div>
                      <Link to={this.state.subscribePath}>
                        <button type="button" className="btn btn-primary">
                          Get Started
                        </button>
                      </Link>
                      <div className="text-secondary">
                        <small style={{ fontSize: "12px" }}>
                          Billed Monthly | Cancel Anytime
                        </small>
                      </div>
                    </div>
                    <div />
                  </Card.Body>
                </Card>
              </Col>
              <Col md="7">
                <div
                  style={{
                    marginTop: "20px"
                  }}
                >
                  <h5>
                    Your free trial ends in less than{" "}
                    {this.state.trialRemainingDays} days
                  </h5>
                  <small style={{ color: "#414a52" }}>
                    Subscribe now to keep Yieldbooster® running after your free
                    trial. Don’t worry, your remaining trial period will not go
                    away.
                  </small>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      );
    } else if (this.state.isFreeTrialExpired) {
      descriptionJsx = (
        <div>
          <Container>
            <Row>
              <Col md="5">
                <Card>
                  <Card.Body className="clearfix">
                    <div className="text-center">
                      <div style={{ marginBottom: "10px" }}>
                        <img src={MedalImage} width="80px" />
                      </div>
                      <div style={{ marginBottom: "20px" }}>
                        <h3>Yieldbooster®</h3>
                        <small style={{ color: "#414a52" }}>
                          Continue Yieldbooster® to keep increasing AdSense &
                          AdX revenue with no coding, no man hours, and no
                          headaches.
                        </small>
                      </div>
                      <Link to={this.state.subscribePath}>
                        <button type="button" className="btn btn-primary">
                          Get Started
                        </button>
                      </Link>
                      <div className="text-secondary">
                        <small style={{ fontSize: "12px" }}>
                          Billed Monthly | Cancel Anytime
                        </small>
                      </div>
                    </div>
                    <div />
                  </Card.Body>
                </Card>
              </Col>
              <Col md="7">
                <div
                  style={{
                    marginTop: "20px"
                  }}
                >
                  <h5>Unsure if Yieldbooster® is right for you?</h5>
                  <small style={{ color: "#414a52" }}>
                    We would be happy to help you find the best solution for
                    your inventory. You can message anytime by click the
                    <i className="fa fa-comment fa-border fa-lg" /> button below
                    to chat with us or just shoot us an email at
                    info@intowow.com!
                  </small>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      );
    } else if (this.state.isInactive) {
      descriptionJsx = (
        <Container>
          <Row>
            <Col sm="1">
              <img
                src={MedalImage}
                width="56px"
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)"
                }}
              />
            </Col>
            <Col>
              <span style={{ fontSize: "larger", fontWeight: "bolder" }}>
                You canceled Yieldbooster® subscription
              </span>
              <br />
              Please contact us if you want to resume subscription or if there
              are any questions
              {/* TODO: should give a message on why, what happened and what to do to resume */}
              <div />
            </Col>
          </Row>
        </Container>
      );
    } else if (this.state.isChargeFailed) {
      descriptionJsx = (
        <Container>
          <Row>
            <Col sm="1">
              <img
                src={MedalImage}
                width="56px"
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)"
                }}
              />
            </Col>
            <Col>
              <span style={{ fontSize: "larger", fontWeight: "bolder" }}>
                You have been suspended from Yieldbooster®.
              </span>
              <br />
              Please contact us if you would like to resume subscription or if
              there are any questions.
              <div />
            </Col>
          </Row>
        </Container>
      );
    } else if (this.state.isSubscribed) {
      descriptionJsx = (
        <Container>
          <Row>
            <Col sm="1">
              <img
                src={MedalImage}
                width="56px"
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)"
                }}
              />
            </Col>
            <Col>
              <span style={{ fontSize: "larger", fontWeight: "bolder" }}>
                Subscribed to Yieldbooster®
              </span>
              <br />
              <div className="text-dark">
                {this.props.nextPaymentDate && (
                  <span>
                    Next payment date{" "}
                    <b>
                      {moment(this.props.nextPaymentDate).format("YYYY/MM/DD")}
                    </b>
                  </span>
                )}
              </div>
              {this.state.trialRemainingDays > 0 && (
                <div>
                  <small className="text-secondary">
                    Since you are still in your free trial, the actual billing
                    date begins on <b>{this.state.billableStartDate}</b>
                  </small>
                </div>
              )}
              <div />
            </Col>
          </Row>
        </Container>
      );
    } else if (this.state.isSubscribing) {
      descriptionJsx = (
        <Card>
          <Card.Header>
            <b>Pricing Detail</b>
          </Card.Header>
          <Card.Body>
            <div>
              <p>Intowow only earns based on what we help you earn.</p>
              <p>
                We charge 30% of the Uplift Revenue instead of your total ad
                revenue.
              </p>
              <p>
                <small>
                  <a href="https://intowow.atlassian.net/wiki/spaces/IYBW/pages/663453828/Performance+Evaluation">
                    Learn more on how we evaluate revenue uplift and performance
                  </a>
                </small>
              </p>
              <hr />
            </div>
            <div>
              {/* <p>Billed monthly on the 6th</p> */}
              <p>Cancel any time, no penalties</p>
              <p>
                <small>
                  <a href="https://intowow.atlassian.net/wiki/spaces/IYBW/pages/648675422/FAQ#FAQ-Billing&Payment">
                    Learn more about our pricing
                  </a>
                </small>
              </p>
            </div>
          </Card.Body>
        </Card>
      );
    } else {
      descriptionJsx = "";
    }

    return <div>{descriptionJsx}</div>;
  }
}

module.exports = SubscriptionDescription;
