import React from "react";
import { Query, Mutation } from "react-apollo";

import CommonStyle from "common/styles/common.css";
import FormStyle from "common/styles/forms.css";
import YbLoadingView from "common/components/yb-loading-view";
import YbLoading from "common/components/yb-loading";
import YbSearch from "common/components/yb-search";
import YbTooltip from "common/components/yb-tooltip";
import Constants from "common/constants/constants";
import { ACCOUNT_CONFIG } from "common/query/publisher";

import {
  mutation as ADD_GAM_ACCOUNT,
  OAuthConfig,
} from "../../integration/query/gam-account";
import OAuth from "../../integration/components/oauth";

import Styles from "../styles/summary.css";
import YbNetwork from "../components/yb-network";
import YbNetworkCandidates from "../components/yb-network-candidates-v2";
import YbStatus from "../components/yb-status";
import YbStickyComponent from "../components/yb-sticky-component";
import YbAddGAMAccountBtn from "../components/yb-add-gam-account-btn";
import {
  GET_INVENTORY,
  GET_ACCOUNTS,
  AUTHORIZE_GAM_ACCOUNT,
} from "../query/summary";

const staticState = {
  searchText: "",
  searchAccountText: "",
  selectedAccountId: "",
  filteredNetwork: "",
  accordion: {
    network: {},
    yieldSet: {},
  },
};

/**
 *  /summary/overview
 *  /summary/overview/:accountId?/:networkId?/:yieldSetId
 */
class SummaryOverview extends React.Component {
  constructor(props) {
    super(props);

    var params = this.props.match.params;
    var accountId = parseInt(params.accountId);
    var networkId = parseInt(params.networkId);
    var yieldSetId = parseInt(params.yieldSetId);

    const pubId = _.get(this.props, "match.params.publisherId");

    this.state = {
      candidatesAmount: -1,
      showModal: false,
      static: staticState,
      reloadExternalSource: false,
      publisherId: _.parseInt(pubId),
    };

    this.reloadExternalSource = this.reloadExternalSource.bind(this);
    this.goToWizard = this.goToWizard.bind(this);

    if (accountId) {
      staticState.selectedAccountId = accountId;

      if (networkId) {
        staticState.accordion.network[networkId] = {
          showYieldSets: true,
        };

        if (yieldSetId) {
          staticState.accordion.yieldSet[yieldSetId] = {
            showAdUnits: true,
            showPlacements: true,
            showContent: true,
            scrollTo: true,
          };
        }
      }
    }

    window.gtagPage("/summary");
  }

  goToWizard() {
    if (this.state.publisherId > 0) {
      this.props.history.push(`/publisher/${this.state.publisherId}/wizard`);
    } else {
      this.props.history.push("/wizard");
    }
  }

  reloadExternalSource() {
    this.setState({
      reloadExternalSource: true,
    });
  }

  accountChanged(accounts, event) {
    var selectedAccountId = parseInt(event.target.value);
    var staticState = this.state.static;

    this.collapseAll();

    staticState.selectedAccountId = selectedAccountId;
    staticState.filteredNetwork = "";

    this.setState({
      candidatesAmount: -1,
      static: staticState,
    });
  }

  selectAccount(account) {
    var staticState = this.state.static;
    this.collapseAll();

    staticState.selectedAccountId = account.accountId;

    this.setState({
      candidatesAmount: -1,
      static: staticState,
    });
  }

  setCandidatesAmount(candidatesAmount) {
    if (this.state.candidatesAmount !== candidatesAmount) {
      this.setState({
        candidatesAmount: candidatesAmount,
        reloadExternalSource: false,
      });
    }
  }

  toggleYieldSet(yieldSet, isAdUnit) {
    var acordionStatus =
      this.state.static.accordion.yieldSet[yieldSet.yieldSetId];

    if (isAdUnit) {
      acordionStatus.showAdUnits = !acordionStatus.showAdUnits;
    } else {
      acordionStatus.showPlacements = !acordionStatus.showPlacements;
    }

    this.forceUpdate();
  }

  toggleYieldSetContent(yieldSet) {
    var acordionStatus =
      this.state.static.accordion.yieldSet[yieldSet.yieldSetId];

    acordionStatus.showContent = !acordionStatus.showContent;

    if (!acordionStatus.showContent) {
      acordionStatus.showAdUnits = false;
      acordionStatus.showPlacements = false;
    }

    this.forceUpdate();
  }

  toggleInventoryItemStatus(item) {
    if (item.status !== "PROCESSING") {
      item.selected = !item.selected;
      this.forceUpdate();
    }
  }

  collapseAll() {
    var accordion = this.state.static.accordion;

    Object.keys(accordion.network).forEach((networkId) => {
      accordion.network[networkId].showYieldSets = false;
    });

    Object.keys(accordion.yieldSet).forEach((yieldSetId) => {
      accordion.yieldSet[yieldSetId].showAdUnits = false;
      accordion.yieldSet[yieldSetId].showPlacements = false;
      accordion.yieldSet[yieldSetId].showContent = false;
    });
  }

  toggleNetwork(network) {
    var acordionStatus = this.state.static.accordion.network[network.networkId];
    acordionStatus.showYieldSets = !acordionStatus.showYieldSets;

    /**
     * Collapse all ad yieldSetsVM
     */
    if (!acordionStatus.showYieldSets) {
      network.yieldSetsVM.forEach((yieldSet) => {
        this.state.static.accordion.yieldSet[
          yieldSet.yieldSetId
        ].showAdUnits = false;
        this.state.static.accordion.yieldSet[
          yieldSet.yieldSetId
        ].showPlacements = false;
        this.state.static.accordion.yieldSet[
          yieldSet.yieldSetId
        ].showContent = false;
      });
    }

    this.forceUpdate();
  }

  syncAdUnits(network, syncAdUnit, event) {
    syncAdUnit({
      variables: {
        input: {
          networkId: network.networkId,
        },
        pubId: this.state.publisherId,
      },
    });

    event.stopPropagation();
  }

  handleChange(text) {
    var staticState = this.state.static;

    staticState.searchText = text;

    this.setState({
      static: staticState,
    });
  }

  handleAccountChange(text) {
    var staticState = this.state.static;

    staticState.searchAccountText = text;

    this.setState({
      static: staticState,
    });
  }

  filteredNetworkChanged() {
    var staticState = this.state.static;

    staticState.filteredNetwork = parseInt(event.target.value) || "";

    this.setState({
      static: staticState,
    });
  }

  renderNewGAMAccountBtn(refetch, accountConfig) {
    if (!accountConfig.gamAccount.enabled) {
      return (
        <span
          disabled
          className={`${CommonStyle.button} ${CommonStyle.buttonNoBorder} ${Styles.newAccBtn}`}
        >
          <i className="fa fa-plus" />
        </span>
      );
    }

    return (
      <YbAddGAMAccountBtn
        callback={refetch}
        publisherId={this.state.publisherId}
      >
        <YbTooltip message={i18n`NEW_GAM_ACCOUNT`} position="top">
          <span
            className={`${CommonStyle.button} ${CommonStyle.buttonNoBorder} ${Styles.newAccBtn}`}
          >
            <i className="fa fa-plus" />
          </span>
        </YbTooltip>
      </YbAddGAMAccountBtn>
    );
  }

  newCandidate() {
    this.setState({
      showModal: true,
    });
  }

  closeCandidates(refetch, wasAdded) {
    setTimeout(() => {
      this.setCandidatesAmount(-1);
      this.setState({
        showModal: false,
        refreshAll: wasAdded,
      });
    }, 50);
  }

  renderNewNetworkBtn(account, accountConfig) {
    var selectedAccountId = this.state.static.selectedAccountId;
    var candidatesAmount = this.state.candidatesAmount;

    if (!accountConfig.gamNetwork.enabled) {
      return (
        <span disabled className={`${CommonStyle.button}`}>
          <i className="fa fa-plus" /> {i18n`NETWORK`}
        </span>
      );
    }

    if (!account) {
      return (
        <span disabled className={`${CommonStyle.button}`}>
          <i className="fa fa-plus" /> {i18n`NETWORK`}{" "}
          {candidatesAmount !== -1 ? `(${candidatesAmount})` : ""}
        </span>
      );
    }

    if (candidatesAmount === -1) {
      return (
        <span disabled className={`${CommonStyle.button}`}>
          <i className="fa fa-plus" /> {i18n`NETWORK`}{" "}
          <YbLoading isWhiteText={true} />
        </span>
      );
    }

    return (
      <span
        className={`${CommonStyle.button}`}
        onClick={this.newCandidate.bind(this)}
      >
        <i className="fa fa-plus" /> {i18n`NETWORK`} {`(${candidatesAmount})`}
      </span>
    );
  }

  renderAuthorizeBtn(accountId, refetch, accountConfig) {
    if (!accountConfig.gamAccount.enabled) {
      return (
        <span disabled className={`${CommonStyle.button}`}>
          <i className="fa fa-link" />
        </span>
      );
    }

    return (
      <Mutation mutation={AUTHORIZE_GAM_ACCOUNT}>
        {(authorizeAdSense, { data, loading, called, error }) => {
          var mismatch = false;

          if (error) {
            if (error.message.match("Google account mismatched")) {
              mismatch = error.message.replace("GraphQL error:", "");
            }
          }

          if (data) {
            refetch();
          }

          return (
            <OAuth
              {...OAuthConfig}
              callback={(code) => {
                authorizeAdSense({
                  variables: {
                    input: {
                      gamAccountId: accountId,
                      authorizationCode: code,
                    },
                    pubId: this.state.publisherId,
                  },
                });
              }}
            >
              <span>
                <YbTooltip message={i18n`REAUTHORIZE`} position="bottom">
                  <span className={`${CommonStyle.button}`}>
                    <i className="fa fa-link" />
                  </span>
                </YbTooltip>
                {error && (
                  <span>
                    {mismatch ? (
                      <span>{mismatch}</span>
                    ) : (
                      <span className={CommonStyle.textDanger}>
                        {i18n`ERROR_AUTHORIZING_ACCOUNT_ELLIPSIS`}
                      </span>
                    )}
                  </span>
                )}
              </span>
            </OAuth>
          );
        }}
      </Mutation>
    );
  }

  renderAccounts(accounts, refetch, accountConfig) {
    var selectedAccountId = this.state.static.selectedAccountId;
    var selectedAccount = accounts.find(
      (account) => account.accountId === selectedAccountId
    );
    var regexp = new RegExp(this.state.static.searchAccountText, "i");

    return (
      <div>
        <YbStickyComponent paddingTop={97}>
          <div className={Styles.accountsWrapper}>
            <div
              className={`${Styles.accountsHeader} ${Styles.searchButtonWrapper}`}
            >
              <YbSearch
                value={this.state.static.searchAccountText}
                placeholder={i18n`FILTER_GAM_ACCOUNTS_ELLIPSIS`}
                onChange={this.handleAccountChange.bind(this)}
              />
              {this.renderNewGAMAccountBtn(refetch, accountConfig)}
            </div>
            <div className={`${FormStyle.form} ${Styles.accountsSelect}`}>
              <select
                value={selectedAccountId}
                onChange={this.accountChanged.bind(this, accounts)}
                required
              >
                <option value="" disabled>
                  Select a GAM Account...
                </option>
                {accounts.map((account, index) => {
                  return (
                    <option key={index} value={account.accountId}>
                      {account.name}
                    </option>
                  );
                })}
              </select>
              {selectedAccount && (
                <span className={CommonStyle.textView}>
                  <YbStatus
                    item={selectedAccount}
                    index={0}
                    total={accounts.length}
                    showStatus={false}
                    itemType="gamAccount"
                  />
                </span>
              )}
            </div>
            <div className={Styles.accountsList}>
              {accounts
                .filter((account) => {
                  return (
                    selectedAccount === account || account.name.match(regexp)
                  );
                })
                .map((account, index) => {
                  var isSelected =
                    selectedAccount &&
                    selectedAccount.accountId === account.accountId;
                  var unauthorized = account.problems.find(
                    (problem) => problem === "UNAUTHORIZED"
                  );

                  return (
                    <div
                      key={index}
                      className={`${Styles.account} ${
                        isSelected ? Styles.selected : ""
                      }`}
                      onClick={this.selectAccount.bind(this, account)}
                    >
                      <span className={`row ${Styles.titleWrapper}`}>
                        {account.name} ({account.gamNetworks.edges.length})
                        <YbStatus
                          item={account}
                          index={index}
                          total={accounts.length}
                          showStatus={false}
                          itemType="gamAccount"
                        />
                        {unauthorized &&
                          this.renderAuthorizeBtn(
                            account.accountId,
                            refetch,
                            accountConfig
                          )}
                      </span>
                    </div>
                  );
                })}
            </div>
          </div>
        </YbStickyComponent>
      </div>
    );
  }

  render() {
    var accountConfig = null;

    return (
      <Query
        query={ACCOUNT_CONFIG}
        variables={{ pubId: this.state.publisherId }}
        fetchPolicy={"network-only"}
      >
        {({ loading, error, data, refetch }) => {
          if (loading) {
            return <YbLoadingView />;
          }

          if (error) {
            return (
              <div
                className={`${CommonStyle.wrapper} ${Styles.summaryWrapper}`}
              >
                <div
                  className={`${CommonStyle.textDanger} ${CommonStyle.textView}`}
                >
                  {i18n`ERROR_LOADING_ACCOUNT_CONFIG_ELLIPSIS`}
                </div>
              </div>
            );
          }

          if (data && data.accountConfig && !accountConfig) {
            accountConfig = data.accountConfig.config.resourceConfig;
          }

          return (
            <Query
              query={GET_ACCOUNTS}
              variables={{ pubId: this.state.publisherId }}
              fetchPolicy={"network-only"}
            >
              {({ loading, error, data, refetch }) => {
                var selectedAccountId = this.state.static.selectedAccountId;
                var accounts = null;
                var account = null;
                var accountIsValid = false;

                var pageTitle = (
                  <div className="col-md-4">
                    <div className={CommonStyle.pageTitle}>
                      {/* Yieldbooster<span style={{ fontSize: "18px" }}>®</span> */}
                      Inventory
                    </div>
                  </div>
                );

                if (this.state.refreshAll) {
                  this.state.refreshAll = false;
                  refetch();
                }

                if (loading) {
                  return <YbLoadingView />;
                }

                if (error) {
                  return (
                    <div
                      className={`${CommonStyle.wrapper} ${Styles.summaryWrapper}`}
                    >
                      <div className={`row ${Styles.overviewHeader}`}>
                        {pageTitle}
                      </div>
                      <div
                        className={`${CommonStyle.textDanger} ${CommonStyle.textView}`}
                      >
                        {i18n`ERROR_LOADING_ACCOUNTS_ELLIPSIS`}
                      </div>
                    </div>
                  );
                }

                accounts = data.gamAccounts.edges.map((edge) => {
                  return edge.node;
                });

                account = accounts.find(
                  (account) => account.accountId === selectedAccountId
                );
                accountIsValid = account && !account.haveProblems;

                return (
                  <div
                    className={`${CommonStyle.wrapper} ${Styles.summaryWrapper}`}
                  >
                    <YbStickyComponent paddingTop={0}>
                      <div className={`row ${Styles.overviewHeader}`}>
                        {pageTitle}
                        {accountIsValid && (
                          <div
                            className={`col-md-8 ${FormStyle.form} ${CommonStyle.textAlignRight} ${Styles.headerActions}`}
                          >
                            <span className={Styles.inputWrapper}>
                              <YbSearch
                                value={this.state.static.searchText}
                                placeholder={i18n`Search Ad Units`}
                                onChange={this.handleChange.bind(this)}
                              />
                            </span>
                            <span className={Styles.inputWrapper}>
                              <select
                                value={this.state.static.filteredNetwork}
                                onChange={this.filteredNetworkChanged.bind(
                                  this
                                )}
                              >
                                <option value="">{i18n`ALL_NETWORKS`}</option>
                                {account.gamNetworks.edges.map(
                                  (network, index) => {
                                    return (
                                      <option
                                        key={index}
                                        value={network.node.networkId}
                                      >
                                        {network.node.name}
                                      </option>
                                    );
                                  }
                                )}
                              </select>
                            </span>
                            {this.renderNewNetworkBtn(account, accountConfig)}
                          </div>
                        )}
                      </div>
                    </YbStickyComponent>
                    <br />
                    <div className="row">
                      <div className={`col-md-3 ${Styles.accountsWrapper}`}>
                        {this.renderAccounts(accounts, refetch, accountConfig)}
                      </div>
                      <div className={`col-md-9 ${CommonStyle.innerWrapper}`}>
                        {!accounts.length && (
                          <div className={CommonStyle.textAlignCenter}>
                            <div
                              className="alert alert-dismissible alert-secondary mx-auto mt-2 w-60"
                              role="alert"
                            >
                              <br />
                              <p>
                                <strong>{i18n`Welcome to Yieldbooster®, let’s get you set up!`}</strong>
                              </p>
                              <p>{i18n`Our Onboarding Wizard is the fastest and easiest way to start increasing your revenue.`}</p>
                              <p>
                                <button
                                  className={`${CommonStyle.buttonPrimary} ${CommonStyle.buttonBig}`}
                                  onClick={this.goToWizard}
                                >
                                  <i className="fa fa-magic" />{" "}
                                  {i18n`Launch Wizard`}
                                </button>
                              </p>
                              <p>
                                {i18n`If you prefer to set up everything manually, we have prepared this detailed `}
                                <a
                                  target="_blank"
                                  className={CommonStyle.buttonLink}
                                  href={Constants.GETTING_STARTED}
                                >{i18n`TUTORIAL`}</a>
                                .
                              </p>
                              <p>{i18n`Don’t hesitate to contact us via the support center with any questions.`}</p>
                            </div>
                          </div>
                        )}
                        {!!accounts.length &&
                          this.renderNetworks(account, accountConfig)}
                      </div>
                    </div>
                  </div>
                );
              }}
            </Query>
          );
        }}
      </Query>
    );
  }

  adjustNetworkData(data, ordersData) {
    var accordion = this.state.static.accordion;
    var networks = data.gamNetworks.edges.map((network) => {
      network = network.node;
      network.availableOrders = [];
      if (!accordion.network[network.networkId]) {
        accordion.network[network.networkId] = {
          showYieldSets: null,
        };
      }

      network.yieldSetsVM = [];
      network.yieldSetsVM = network.yieldSets.edges.map((yieldSet) => {
        yieldSet = yieldSet.node;
        if (!accordion.yieldSet[yieldSet.yieldSetId]) {
          accordion.yieldSet[yieldSet.yieldSetId] = {
            showContent: null,
            showAdUnits: null,
            showPlacements: null,
          };
        }

        if (yieldSet.adsenseAccount) {
          yieldSet.sizeOptimizations =
            yieldSet.adsenseAccount.adsenseAdUnits.edges
              .filter((edge) => {
                return edge.node.type === "OPTIMIZATION";
              })
              .map((edge) => {
                return edge.node;
              });
        } else {
          yieldSet.sizeOptimizations = [];
        }

        yieldSet.status = yieldSet.status || "";
        yieldSet.adUnits = yieldSet.gamAdUnits.edges.map((adUnit) => {
          return adUnit.node;
        });

        yieldSet.placements = yieldSet.gamPlacements.edges.map((placement) => {
          return placement.node;
        });

        yieldSet.optimizations = [];

        if (yieldSet.adsenseAccount) {
          yieldSet.optimizations = yieldSet.adsenseAccount.adsenseAdUnits.edges
            .map((edge) => edge.node)
            .filter((item) => item.type === "OPTIMIZATION")
            .map((item) => item.adSize);
        }

        return yieldSet;
      });

      return network;
    });

    return networks;
  }

  renderNetworkList(refetch, accountConfig, gamAccountId) {
    var networks = this.state.networks;
    var filteredNetwork = this.state.static.filteredNetwork;

    return (
      <div>
        {networks
          .filter((network) => {
            return !filteredNetwork || network.networkId === filteredNetwork;
          })
          .map((network, networkIndex) => {
            return (
              <YbNetwork
                gamAccountId={gamAccountId}
                publisherId={this.state.publisherId}
                key={networkIndex}
                accountConfig={accountConfig}
                network={network}
                networkIndex={networkIndex}
                refetch={refetch}
                accordion={this.state.static.accordion}
                networksAmount={this.state.networks.length}
                history={this.props.history}
                searchText={this.state.static.searchText}
                toggleNetwork={this.toggleNetwork.bind(this)}
                toggleYieldSet={this.toggleYieldSet.bind(this)}
                toggleYieldSetContent={this.toggleYieldSetContent.bind(this)}
                toggleInventoryItemStatus={this.toggleInventoryItemStatus.bind(
                  this
                )}
                syncAdUnits={this.syncAdUnits.bind(this)}
              />
            );
          })}
        {!this.state.networks.length && (
          <div
            className={`${CommonStyle.textAlignCenter} ${CommonStyle.textView}`}
          >{i18n`No Available Networks!`}</div>
        )}
      </div>
    );
  }

  renderNetworks(selectedAccount, accountConfig) {
    var variables = null;
    var showModal = this.state.showModal;

    if (!selectedAccount) {
      return (
        <div className={CommonStyle.textView}>
          {i18n`PLEASE_SELECT_A_GAM_ACCOUNT_TO_DISPLAY_THE_AVAILABLE_NETWORKS`}
        </div>
      );
    }

    if (selectedAccount.haveProblems) {
      return (
        <div className={CommonStyle.textView}>
          {i18n`THIS_GAM_ACCOUNT_IS_NOT_AUTHORIZED`}
        </div>
      );
    }

    variables = {
      filter: {
        publisherId: selectedAccount.publisher.publisherId,
        accountIds: [selectedAccount.accountId],
      },
      pubId: this.state.publisherId,
    };

    return (
      <Query
        query={GET_INVENTORY}
        variables={variables}
        fetchPolicy={"network-only"}
      >
        {({ loading, error, data, refetch }) => {
          var adUnitsAmount = 0;

          if (loading) {
            return <YbLoadingView />;
          }

          if (error || (data && !data.gamAccounts.edges.length)) {
            setTimeout(() => {
              this.setState({
                candidatesAmount: 0,
              });
            }, 0);

            return (
              <div className={Styles.networkCandidate}>
                <div
                  className={`row ${Styles.titleWrapper} ${Styles.errorMsg} ${CommonStyle.textAlignCenter} text-danger`}
                >
                  <div className="col-md-12">
                    {i18n`ERROR_LOADING_NETWORKS_ELLIPSIS`}
                  </div>
                </div>
              </div>
            );
          }

          data = data.gamAccounts.edges[0].node;

          if (data.gamNetworks.edges[0]) {
            adUnitsAmount = data.gamNetworks.edges[0].node.yieldSets.edges
              .map((edge) => {
                return edge.node.gamAdUnits.edges.length;
              })
              .reduce((result, amount) => {
                return result + amount;
              }, 0);
          }

          this.state.networks = this.adjustNetworkData(data);

          return (
            <div>
              {this.renderNetworkList(
                refetch,
                accountConfig,
                selectedAccount.accountId
              )}
              <YbNetworkCandidates
                inModal={showModal}
                loaded={this.setCandidatesAmount.bind(this)}
                callback={this.closeCandidates.bind(this, refetch)}
                account={selectedAccount}
                networks={this.state.networks}
                refetch={refetch}
                reloadExternalSource={this.state.reloadExternalSource}
                publisherId={this.state.publisherId}
              />
            </div>
          );
        }}
      </Query>
    );
  }
}

module.exports = SummaryOverview;
