import React from "react";
import { Query, Mutation } from "react-apollo";

import CommonStyle from "common/styles/common.css";
import FormStyle from "common/styles/forms.css";

import YbLoadingView from "common/components/yb-loading-view";
import YbHighlight from "common/components/yb-highlight";
import YbTreeSelector from "common/components/yb-tree-selector";
import YbLoading from "common/components/yb-loading";
import YbModal from "common/components/yb-modal";

import YbAccountPreview from "../../summary/components/yb-account-preview";

import { TOGGLE_AD_UNITS } from "../../summary/query/summary";
import { GET_AD_UNITS } from "../../summary/query/summary";

class YbWizardStep6 extends React.Component {
  constructor(props) {
    super(props);

    this.getNextStepBtn = this.getNextStepBtn.bind(this);
    this.onFinishModalHide = this.onFinishModalHide.bind(this);
    this.whatsNextClosed = this.whatsNextClosed.bind(this);

    this.state = {
      publisherId: props.publisherId,
    };
  }

  onFinishModalHide() {
    this.props.updateCurrentData((stepData) => {
      stepData.showFinishModal = false;
    });
  }

  whatsNextClosed() {
    this.props.updateCurrentData((stepData) => {
      stepData.showWhatsNext = false;
      stepData.nextStepFunc();
    });
  }

  getNextStepBtn(nextStepFunc) {
    var stepData = this.props.stepData;

    return (
      <span>
        <Query
          query={GET_AD_UNITS}
          variables={{ pubId: this.state.publisherId }}
          fetchPolicy={"network-only"}
        >
          {({ loading, error, data }) => {
            if (loading) {
              return (
                <span className={FormStyle.submitLoading}>
                  <YbLoading />
                </span>
              );
            }

            if (error) {
              return (
                <span className={FormStyle.submitLoading}>
                  <span className="text-danger">
                    {i18n`Error getting ad units...`}
                  </span>
                </span>
              );
            }

            stepData.savedAdUnits = data.gamAdUnits.edges.map(
              (edge) => edge.node
            );
            return (
              <Mutation mutation={TOGGLE_AD_UNITS}>
                {(toggleStatusRequest, { loading, error, data }) => {
                  if (loading) {
                    return (
                      <span className={FormStyle.submitLoading}>
                        <YbLoading />
                      </span>
                    );
                  }

                  if (error) {
                    return (
                      <span className={FormStyle.submitLoading}>
                        <span className="text-danger">
                          {i18n`Error running ad units...`}
                        </span>
                      </span>
                    );
                  }

                  if (data) {
                    if (!stepData.nextStepFunc) {
                      setTimeout(() => {
                        this.props.updateCurrentData((stepData) => {
                          stepData.showWhatsNext = true;
                          stepData.nextStepFunc = nextStepFunc;
                        });
                      });
                    }

                    return <span />;
                  }

                  return (
                    <span>
                      {/* <button
                        className={FormStyle.submitBtn}
                        onClick={() => {
                          var input = {
                            yieldSetId: stepData.selectedYieldSet.yieldSetId
                          };
                          var params = {
                            variables: {
                              adUnitInput: {
                                status: "RUNNING"
                              },
                              pubId: this.state.publisherId
                            }
                          };

                          params.variables.adUnitInput.adUnitIds = stepData.items.map(
                            adUnit => {
                              return stepData.savedAdUnits.find(
                                savedAdUnit =>
                                  savedAdUnit.extGamAdUnitId === adUnit.extId
                              ).adUnitId;
                            }
                          );

                          this.props.updateCurrentData(stepData => {
                            stepData.isLoading = true;

                            setTimeout(() => {
                              toggleStatusRequest(params);
                            });
                          });
                        }}
                      >
                        <span>
                          <i className="fa fa-play" />{" "}
                          {i18n`Finish & Run Ad Unit(s)`}
                        </span>
                      </button> */}
                      <button
                        // className={`${FormStyle.secondaryBtn}`}
                        className={FormStyle.submitBtn}
                        onClick={() => {
                          this.props.updateCurrentData((stepData) => {
                            stepData.showFinishModal = false;
                            nextStepFunc();
                            // stepData.showFinishModal = true;
                          });
                        }}
                      >
                        <span>
                          <i className="fa fa-check" /> {i18n`Finish`}
                        </span>
                      </button>
                    </span>
                  );
                }}
              </Mutation>
            );
          }}
        </Query>
        <YbModal
          show={stepData.showFinishModal}
          title={i18n`Alert`}
          successText={i18n`YES`}
          cancelText={i18n`NO`}
          onHide={this.onFinishModalHide}
          onSubmit={nextStepFunc}
          succesIcon="fa-check"
        >
          <div className={CommonStyle.textAlignCenter}>
            <div className={CommonStyle.highlightedText}>
              <b>{i18n`Are you sure that you want to Finish the wizard without running Ad Unit(s)?`}</b>
            </div>
            <br />
            <div>
              {i18n`All onboarded Ad units will remained `}
              <span className={CommonStyle.textDanger}>
                <b>{i18n`Paused`}</b>
              </span>
              .
            </div>
            <div>{i18n`You can manually run the Ad units from your Inventory.`}</div>
            <br />
            <div>{i18n`You can manually run the Ad units from your Inventory.`}</div>
            <br />
            <div>
              {i18n`Once your ad units are running, your revenue uplift can be monitored on the dashboard`}
              (
              <span className={CommonStyle.highlightedText}>
                <i className="fa fa-line-chart" />
              </span>
              ).
            </div>
          </div>
        </YbModal>
      </span>
    );
  }

  render() {
    var stepData = this.props.stepData;

    stepData.getNextStepBtn = this.getNextStepBtn;

    return (
      <div>
        <YbAccountPreview
          account={stepData.selectedAccount}
          network={stepData.selectedNetwork}
          yieldSet={stepData.selectedYieldSet}
          adUnits={stepData.items}
        />
        <YbModal
          show={stepData.showWhatsNext}
          noSuccessBtn={true}
          cancelText={i18n`close`}
          title={i18n`What's Next?`}
          onHide={this.whatsNextClosed}
        >
          <div>
            <p className={`${CommonStyle.textAlignCenter}`}>
              <div
                className={CommonStyle.highlightedText}
              >{i18n`You are all set!`}</div>
              <br />
              {i18n`Yieldbooster® is preparing to optimize your inventory. Your revenue uplift can be monitored on the dashboard`}
              (
              <span className={CommonStyle.highlightedText}>
                <i className="fa fa-line-chart" />
              </span>
              ).
              <br />
              {i18n`You’ll be seeing updated results in the following 24 hours!`}
            </p>
          </div>
        </YbModal>
      </div>
    );
  }
}

module.exports = YbWizardStep6;
