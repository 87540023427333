import React, { useEffect } from "react";
import { Button, Card } from "react-bootstrap";
import intowowLogo from "../../../common/images/intowow-wand-final.png";
import gLogo from "../../common/images/g-logo.png";

import Styles from "../../../common/styles/common.css";

import { API_ENDPOINT } from "../../common/constants/common";
import ThirdPartyCookieChecker from "../components/third-party-cookie-checker";
import UserNotRecognizedAlertBox from "../components/user-not-recognized-alert-box";

function Login(props) {
  const { isInvalidUser } = props;

  useEffect(() => {
    //002371
    //01133c
    //001b56
    // document.body.style.backgroundColor = "#002371";
    document.body.style.backgroundColor = "#011542";
    // document.body.style.backgroundColor = "#01133c";
    return () => {
      document.body.style.backgroundColor = null;
    };
  });

  function openTerms() {
    window.location.href = "/#/terms";
  }

  function handleLogin() {
    const redirectUrl = `${window.location.origin}/#/signup`; // home page
    const errorRedirectUrl = `${window.location.origin}/#/login-unrecognized-user`; // error page

    const ru = encodeURIComponent(redirectUrl);
    const eru = encodeURIComponent(errorRedirectUrl);

    const loginEndpoint = `${API_ENDPOINT}/login?redirect_uri=${ru}&error_redirect_uri=${eru}`;

    console.log(loginEndpoint);
    console.log("logging in");

    window.location.href = loginEndpoint;
  }

  return (
    <React.Fragment>
      <div
        style={{
          paddingTop: "80px",
          paddingBottom: "120px",
        }}
      >
        <div className="d-flex justify-content-center align-self-center">
          <div style={{ width: "60%" }}>
            <div className="d-flex justify-content-center pb-4">
              <div
                style={{
                  // background: "linear-gradient(to bottom, #f83600, #fe8c00)",
                  // background: "linear-gradient(to top, #2B32B2, #1488CC)",
                  backgroundColor: "#ff5622",
                  borderRadius: "30px",
                  border: "4px solid black",
                }}
              >
                <img src={intowowLogo} width="52px" />
              </div>
            </div>

            <Card
              className="text-center shadow-lg"
              style={{
                borderRadius: "8px",
                padding: "24px",
                background: "#fbfeff",
              }}
            >
              <Card.Body>
                <h1 className="mt-2 mb-4">Login</h1>

                {/* <p className="text-secondary">
                  Don't have an account? <a href="/#/newuser">Sign up here →</a>
                </p> */}

                <div>
                  <ThirdPartyCookieChecker></ThirdPartyCookieChecker>
                  {isInvalidUser && (
                    <UserNotRecognizedAlertBox></UserNotRecognizedAlertBox>
                  )}
                  <Button
                    variant="light"
                    size="lg"
                    className="shadow bg-white rounded"
                    onClick={handleLogin}
                  >
                    <div className="d-flex align-items-center pl-5 pr-5 pt-2 pb-2">
                      <img src={gLogo} width="24px" height="24px" />
                      <span
                        className="ml-3 text-dark"
                        style={{ fontSize: "18px" }}
                      >
                        Login with Google
                      </span>
                    </div>
                  </Button>
                </div>
                <br />
                <div className={Styles.termsWrapper}>
                  <span>{i18n`By continuing, you agree to our`}</span>{" "}
                  <span>
                    <button onClick={openTerms} className={Styles.buttonLink}>
                      {i18n`Service agreement`}
                    </button>
                  </span>
                </div>
              </Card.Body>
            </Card>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Login;
