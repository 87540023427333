import React from "react";

// SHOULD ONLY BE IN STAGING BRANCH
const StagingWarningHeader = (props) => {
  if (process.env.INTOWOW_ENV === "staging") {
    return (
      <div
        style={{
          height: "36px",
          backgroundColor: "#da1a2d",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            color: "#fafafa",
            alignSelf: "center",
          }}
        >
           ⚠️ CAREFUL! THIS IS STAGING ENV!
        </div>
      </div>
    );
  }

  return "";
};

export default StagingWarningHeader;
