import React from "react";
import { Query } from "react-apollo";
import { withRouter } from "react-router-dom";
import YbLoadingView from "common/components/yb-loading-view";
import { Card, Button, Row, Col, Alert } from "react-bootstrap";

import SubscriptionDescription from "../components/subscription-description";
import PaymentInfoView from "../components/payment-info-view";
import BillingInfoView from "../components/billing-info-view";
import PaymentFailedAlert from "../components/payment-failed-alert";
import CurrentPaymentInfo from "../components/current-payment-info";

import _ from "lodash";

import { GET_ALL_SUBSCRIPTION_NEEDED_INFO } from "../query/subscription-and-billing";

/**
 *  /subscription-and-billing/overview
 */
function SubscriptionAndBillingOverview(props) {
  window.gtagPage("/subscription-and-billing");

  function handleFillInBillingInfo() {
    const path = "/subscription-and-billing/billing-setting";
    props.history.push(path);
  }

  function handleUpdateBillingInfo() {
    const path = "/subscription-and-billing/update-billing-info";
    props.history.push(path);
  }

  function handleUpdatePaymentInfo() {
    const path = "/subscription-and-billing/update-payment-info";
    props.history.push(path);
  }

  return (
    <div>
      <Query
        query={GET_ALL_SUBSCRIPTION_NEEDED_INFO}
        fetchPolicy={"network-only"}
      >
        {({ loading, error, data }) => {
          if (loading) {
            return <YbLoadingView />;
          }

          if (error) {
            console.log("GET_ALL_SUBSCRIPTION_NEEDED_INFO", error);
            return "";
          }

          const info = data.publisherAccountStatus;
          const publisherInfo = info.publisher;
          const statusInfo = info.status.trialStatus;

          const subscriptionStatus = publisherInfo.accountType;
          const trialRemainingDays = statusInfo.remainingDays;
          const billableStartDate = statusInfo.billableStartDate;

          // Some users are active but DON'T HAVE billingSetting!!!!!
          let hasBillingSetting =
            publisherInfo.billingSetting &&
            publisherInfo.billingSetting.activeBillingProfile;

          // get payments
          let payments = _.get(data, "payments.edges");
          payments = payments.map(node => {
            // transform backend data to frontend data
            let paymentStatus = null;
            const status = _.get(node, "node.collectionStatus");
            const overallPaymentStatus = _.get(node, "node.status");

            if (status === "PAID") {
              paymentStatus = "SUCCESS";
            }

            if (status === "PENDING") {
              paymentStatus = "WAITING_FOR_TRANSACTION";
            }

            if (status === "FAILED") {
              paymentStatus = "FAILED";
            }

            if (overallPaymentStatus === "BAD_DEBT") {
              paymentStatus = "EXPIRED";
            }

            return {
              paymentStatus,
              nextPaymentDate: _.get(node, "node.nextCollectionDate"),
              finalPaymentDate: _.get(node, "node.finalCollectionDate")
            };
          });

          // order by next payment date ascending order
          payments.sort((a, b) => {
            return new Date(a.nextPaymentDate) - new Date(b.nextPaymentDate);
          });

          // just get the first payment to get the most early payment record
          const currentPaymentInfo = _.first(payments);
          const currentPaymentStatus = _.get(
            currentPaymentInfo,
            "paymentStatus"
          );
          const nextPaymentDate = _.get(currentPaymentInfo, "nextPaymentDate");
          const finalPaymentDate = _.get(
            currentPaymentInfo,
            "finalPaymentDate"
          );

          return (
            <div>
              <div>
                {subscriptionStatus === "ACTIVE" && trialRemainingDays > 0 && (
                  <Alert variant="info">
                    <div className="text-center">
                      You are in the your free trial period with{" "}
                      {trialRemainingDays} days remaining
                    </div>
                  </Alert>
                )}

                {subscriptionStatus === "ACTIVE" && !hasBillingSetting && (
                  <Alert variant="warning" className="text-center">
                    <Alert.Heading>
                      We are missing some crucial information!
                    </Alert.Heading>
                    <p>
                      Keep Yieldbooster® running by letting us know your payment
                      and contact information. Please fill them in as soon as
                      possible.
                    </p>
                    <div className="d-flex justify-content-center">
                      <Button
                        onClick={handleFillInBillingInfo}
                        variant="primary"
                      >
                        Fill In Billing Information
                      </Button>
                    </div>
                  </Alert>
                )}

                {(currentPaymentStatus === "FAILED" ||
                  currentPaymentStatus === "EXPIRED") && (
                  <PaymentFailedAlert
                    paymentStatus={currentPaymentStatus}
                    nextPaymentDate={nextPaymentDate}
                    finalPaymentDate={finalPaymentDate}
                  />
                )}

                {/* Don't have these info from backend... */}
                {/* <CurrentPaymentInfo currentPaymentInfo={currentPaymentInfo} />
                <br /> */}

                <Row>
                  <Col>
                    <Card>
                      <Card.Header>Your Subscription</Card.Header>
                      <Card.Body>
                        {/* FOR TESTING: to see them in different account types */}
                        {/* <SubscriptionDescription isInactive={true} />
                        <SubscriptionDescription
                          isInFreeTrial={true}
                          trialRemainingDays={trialRemainingDays}
                        />
                        <SubscriptionDescription isFreeTrialExpired={true} />
                        <SubscriptionDescription
                          isSubscribed={true}
                          billableStartDate={billableStartDate}
                        />
                        <SubscriptionDescription isChargeFailed={true} /> */}

                        {subscriptionStatus === "TRIAL" && (
                          <SubscriptionDescription
                            isInFreeTrial={true}
                            trialRemainingDays={trialRemainingDays}
                          />
                        )}

                        {subscriptionStatus === "TRIAL_EXPIRED" && (
                          <SubscriptionDescription isFreeTrialExpired={true} />
                        )}

                        {subscriptionStatus === "ACTIVE" && (
                          <SubscriptionDescription
                            isSubscribed={true}
                            billableStartDate={billableStartDate}
                            trialRemainingDays={trialRemainingDays}
                            nextPaymentDate={nextPaymentDate}
                          />
                        )}

                        {subscriptionStatus === "INACTIVE" && (
                          <SubscriptionDescription isInactive={true} />
                        )}

                        {subscriptionStatus === "CHARGE_FAILED" && (
                          <SubscriptionDescription isChargeFailed={true} />
                        )}
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>

                <br />

                <Row>
                  {subscriptionStatus === "ACTIVE" && hasBillingSetting && (
                    <Col lg="6">
                      <Card>
                        <Card.Header>
                          Payment Method
                          {/* <Button
                            variant="link"
                            size="sm"
                            type="button"
                            className="float-right"
                            onClick={handleUpdatePaymentInfo}
                          >
                            Update
                          </Button> */}
                        </Card.Header>
                        <Card.Body>
                          <PaymentInfoView
                            billingSetting={publisherInfo.billingSetting || {}}
                          />
                        </Card.Body>
                      </Card>
                      <br />
                    </Col>
                  )}

                  {subscriptionStatus === "ACTIVE" && hasBillingSetting && (
                    <Col lg="6">
                      <Card>
                        <Card.Header>
                          Billing Information
                          {/* <Button
                            variant="link"
                            size="sm"
                            type="button"
                            className="float-right"
                            onClick={handleUpdateBillingInfo}
                          >
                            Update
                          </Button> */}
                        </Card.Header>
                        <Card.Body>
                          <BillingInfoView
                            billingSetting={publisherInfo.billingSetting || {}}
                          />
                        </Card.Body>
                      </Card>
                    </Col>
                  )}
                </Row>
              </div>
            </div>
          );
        }}
      </Query>
    </div>
  );
}

module.exports = withRouter(SubscriptionAndBillingOverview);
