import React from "react";
import queryString from "query-string";
import { Container, Row, Col } from "react-bootstrap";

import CommonStyle from "common/styles/common.css";

import BillingSettingForm from "../components/billing-setting-form";

/**
 *  /subscription-and-billing/billing-setting
 */

class BillingSetting extends React.Component {
  constructor(props) {
    super(props);
    window.gtagPage("/billing-setting");

    let activeProfileId = null;
    let isPaymentFailed = false;

    // Check if it is being redirected with query string
    // status: SUCCESS / FAILED
    // profileId: the registered profile id
    const redirectedData = queryString.parse(this.props.location.search);

    if (redirectedData.status === "SUCCESS" && redirectedData.profileId) {
      activeProfileId = redirectedData.profileId;
      // TODO: status does not return as "FAILED"
      // example of failed url: xxx?status=MPG03009&profileId=null
    } else if (redirectedData.status && redirectedData.status !== "SUCCESS") {
      isPaymentFailed = true;
    } else {
      isPaymentFailed = false;
      activeProfileId = null;
    }

    this.state = {
      activeProfileId,
      isPaymentFailed,
      // the path for newebpay to redirect back after credit card registration
      thirdPartyRedirectPath: this.props.location.pathname
    };
  }

  render() {
    return (
      <div>
        <div className={CommonStyle.wrapper}>
          <Container>
            <Row>
              <Col sm="2" />
              <Col>
                <div className={CommonStyle.pageTitle}>Billing Settings</div>
                <div className="text-secondary" style={{ marginLeft: "18px" }}>
                  Tell us your billing information so we can keep your inventory
                  boosting with revenue!
                </div>
                <hr />
                <BillingSettingForm
                  activeProfileId={this.state.activeProfileId}
                  isPaymentFailed={this.state.isPaymentFailed}
                  isSubscribe={false}
                  thirdPartyRedirectPath={this.state.thirdPartyRedirectPath}
                />
              </Col>
              <Col sm="2" />
            </Row>
          </Container>
        </div>
      </div>
    );
  }
}

module.exports = BillingSetting;
