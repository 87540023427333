import React from "react";
import { Query, Mutation } from "react-apollo";

import CommonStyle from "common/styles/common.css";

import YbTooltip from "common/components/yb-tooltip";
import YbHighlight from "common/components/yb-highlight";

import OAuth from "../../integration/components/oauth";
import {
  mutation as ADD_GAM_ACCOUNT,
  OAuthConfig
} from "../../integration/query/gam-account";

import UserStyles from "../../user/styles/signup.css";

class YbAddGAMAccountBtn extends React.Component {
  constructor(props) {
    super(props);

    this.triggerAddGamAccount = this.triggerAddGamAccount.bind(this);
    this.renderGAMAccountModal = this.renderGAMAccountModal.bind(this);

    this.state = {
      addGAMAccount: false
    };
  }

  triggerAddGamAccount() {
    this.setState({
      addGAMAccount: true
    });
  }

  renderGAMAccountModal() {
    var authorizeNow = this.state.addGAMAccount;
    var gamSubmitted = this.state.gamSubmitted;
    var callback = this.props.callback;

    return (
      <Mutation mutation={ADD_GAM_ACCOUNT}>
        {(addGamAccount, { data, loading, error, called, client }) => {
          if (authorizeNow) {
            setTimeout(() => {
              this.setState({
                addGAMAccount: false
              });
            });
          }

          return (
            <OAuth
              {...OAuthConfig}
              authorizeNow={authorizeNow}
              modal={{
                error: gamSubmitted && error,
                title: i18n`NEW_GAM_ACCOUNT`,
                fields: [
                  {
                    key: "name",
                    title: i18n`NAME`,
                    placeholder: i18n`EXAMPLE_AT_DOMAIN_DOT_COM_ELLIPSIS`,
                    extra: (
                      <span>
                        <YbTooltip
                          big={true}
                          position="auto"
                          message={
                            <YbHighlight
                              text={i18n`Yieldbooster® supports setting up multiple GAM accounts. The Name of your GAM account is an alias to help you easily recognize your account. (Example: NBC(nbc.com))`}
                              highlight={[
                                i18n`Name`,
                                i18n`New GAM Account`,
                                i18n`Create`
                              ]}
                            />
                          }
                        >
                          <span
                            className={`${CommonStyle.buttonLinkLight} ${
                              UserStyles.infoTooltip
                            }`}
                          >
                            <i className="fa fa-info-circle" />
                          </span>
                        </YbTooltip>
                      </span>
                    )
                  }
                ],
                closeCallback: () => {
                  this.setState({
                    gamSubmitted: false
                  });
                },
                disabled: loading,
                onSubmit: ({ name, code }) => {
                  this.setState({
                    gamSubmitted: true
                  });

                  return addGamAccount({
                    variables: { name, code, pubId: this.props.publisherId }
                  }).then(({ data }) => {
                    this.setState({
                      gamSubmitted: false
                    });

                    if (callback) {
                      setTimeout(() => {
                        callback(data);
                      });
                    }
                  });
                }
              }}
            />
          );
        }}
      </Mutation>
    );
  }

  render() {
    return (
      <span>
        <span onClick={this.triggerAddGamAccount}>{this.props.children}</span>
        {this.renderGAMAccountModal()}
      </span>
    );
  }
}

module.exports = YbAddGAMAccountBtn;
