import React from "react";
import { withRouter, matchPath } from "react-router-dom";

import BaseRouter from "../routes/base-router";
import Header from "../components/header";
import NavigationBar from "../components/navigation-bar";
import YbFooter from "../../../common/components/yb-footer";
import SubscribeCtaHeader from "../components/subscribe-cta-header";

import {
  UserInfoContext,
  PublisherInfoContext,
} from "../../common/contexts/contexts";
import UserInfoContextProvider from "../../common/contexts/user-info-context-provider";
import PublisherInfoContextProvider from "../../common/contexts/publisher-info-context-provider";
import StagingWarningHeader from "../components/staging-warning-header";

class Home extends React.Component {
  constructor(props) {
    super(props);
    // TODO: change title when path changes
    // document.title = "YB | Home";

    this.state = {
      matchPubId: null,
    };
  }

  getMatchParams(pathname) {
    const match = matchPath(pathname, {
      path: "/publisher/:publisherId",
    });
    const matchPubId = _.get(match, "params.publisherId");
    return matchPubId;
  }

  changeMatchingPublisher(handlePublisherChange, pubInfo) {
    this.setState({
      matchPubId: pubInfo.publisherId,
    });
    handlePublisherChange(pubInfo);
  }

  componentDidMount() {
    const matchingPubId = this.getMatchParams(this.props.location.pathname);
    this.setState({
      matchPubId: matchingPubId,
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      // Route changed!

      console.log(
        "route changed",
        prevProps.location.pathname,
        this.props.location.pathname
      );

      const matchingPubId = this.getMatchParams(this.props.location.pathname);
      if (matchingPubId !== this.state.matchPubId) {
        this.setState({
          matchPubId: matchingPubId,
        });
      }
    }
  }

  render() {
    const { matchPubId } = this.state;
    const { loginAsPubId, handleSwitchLoginAs } = this.props;

    return (
      <div>
        <UserInfoContextProvider loginAsPubId={loginAsPubId}>
          <PublisherInfoContextProvider publisherId={matchPubId}>
            <UserInfoContext.Consumer>
              {({ userInfo }) => (
                <React.Fragment>
                  {userInfo ? (
                    <PublisherInfoContext.Consumer>
                      {({ pubId, publisherInfo, handlePublisherChange }) => {
                        return (
                          <React.Fragment>
                            {process.env.INTOWOW_ENV === "staging" && (
                              <StagingWarningHeader></StagingWarningHeader>
                            )}

                            <SubscribeCtaHeader userInfo={userInfo} />
                            <Header
                              userInfo={userInfo}
                              publisherInfo={publisherInfo}
                              handleSwitchPublisher={(
                                pubInfo,
                                redirectPath
                              ) => {
                                return this.changeMatchingPublisher(
                                  handlePublisherChange,
                                  pubInfo,
                                  redirectPath
                                );
                              }}
                              handleSwitchLoginAs={handleSwitchLoginAs}
                            />
                            <NavigationBar
                              userInfo={userInfo}
                              publisherId={pubId}
                              isResellerControllingPublisher={pubId > 0}
                            />
                          </React.Fragment>
                        );
                      }}
                    </PublisherInfoContext.Consumer>
                  ) : (
                    // "loading"
                    ""
                  )}
                </React.Fragment>
              )}
            </UserInfoContext.Consumer>

            <BaseRouter />
            <YbFooter />
          </PublisherInfoContextProvider>
        </UserInfoContextProvider>
      </div>
    );
  }
}

export default withRouter(Home);
