import React from "react";
import ReactTooltip from "react-tooltip";

class MetricTooltip extends React.PureComponent {
  render() {
    const { tooltipKey, tooltipMessage, place } = this.props;

    return (
      <React.Fragment>
        <span data-tip data-for={tooltipKey} style={styles.dashedUnderline}>
          {this.props.children}
        </span>

        <ReactTooltip
          id={tooltipKey}
          type="dark"
          effect="solid"
          place={place || "top"}
        >
          {tooltipMessage}
        </ReactTooltip>
      </React.Fragment>
    );
  }
}

const styles = {
  dashedUnderline: {
    borderBottom: "1px dashed lightgray",
  },
};

export default MetricTooltip;
