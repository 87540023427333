import React from "react";
import TrendSynchedChart from "./trend-synched-chart";

class TrendSection extends React.Component {
  render() {
    const {
      reports,
      summaryData,
      selectedPubId,
      selectedNetworkId,
      selectedYieldSetId,
      startDate,
      endDate,
      selectedCurrency,
    } = this.props;

    return (
      <React.Fragment>
        <div className="mt-4" style={styles.sectionTitle}>
          Performance Trend
        </div>

        <TrendSynchedChart
          reports={reports}
          summaryData={summaryData}
          selectedPubId={selectedPubId}
          selectedNetworkId={selectedNetworkId}
          selectedYieldSetId={selectedYieldSetId}
          startDate={startDate}
          endDate={endDate}
          selectedCurrency={selectedCurrency}
        ></TrendSynchedChart>
      </React.Fragment>
    );
  }
}

const styles = {
  sectionTitle: {
    fontSize: "28px",
    marginBottom: "16px",
    color: "#374151",
    fontWeight: "600",
  },
  statText: {
    fontSize: "36px",
  },
};

export default TrendSection;
