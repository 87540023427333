import React from "react";

import CommonStyle from "../../../common/styles/common.css";
import YbLoading from "common/components/yb-loading";

import { fetchRespJson } from "../helpers/api-helper";
import HomeDashboardViewV2 from "./home-dashboard-view";
import HomeDashboardView from "../../home-dashboard/states/home-dashboard-view";

class HomeDashboardWrapper extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      errMsg: null,

      resultData: null,
      version: null,
    };
  }

  async componentDidMount() {
    this.setState({ isLoading: true });
    try {
      const resultData = await fetchRespJson(this.props.reportsUrl);

      if (!resultData) {
        throw new Error("No Data");
      }

      const version = _.get(resultData, "version", "v1");
      console.log(resultData.version);

      this.setState({
        version,
        resultData,
      });
    } catch (err) {
      console.log(err);
      this.setState({ errMsg: "Unable to get home dashboard reports." });
    }

    this.setState({ isLoading: false });
  }

  render() {
    const {
      isLoading,
      errMsg,

      resultData,
      version,
    } = this.state;

    if (isLoading) {
      return (
        <div className={CommonStyle.textAlignCenter}>
          <YbLoading></YbLoading>
        </div>
      );
    }

    if (errMsg) {
      return <div>{errMsg}</div>;
    }

    if (!resultData) return "";

    if (version === "v2") {
      return (
        <HomeDashboardViewV2 resultData={resultData}></HomeDashboardViewV2>
      );
    }

    return <HomeDashboardView resultData={resultData}></HomeDashboardView>;
  }
}

export default HomeDashboardWrapper;
