import React from "react";
import { Redirect } from "react-router-dom";

import FormStyle from "common/styles/forms.css";
import CommonStyle from "common/styles/common.css";
import YbLoading from "common/components/yb-loading";
import YbLoadingView from "common/components/yb-loading-view";
import YbTooltip from "common/components/yb-tooltip";
import YbHighlight from "common/components/yb-highlight";
import YbModal from "common/components/yb-modal";
import Constants from "common/constants/constants";

import adSenseStep1 from "common/images/adsense_payment_setting.png";
import adSenseStep2 from "common/images/adsense_payment_profile_country.png";
import adManagerStep1 from "common/images/admanager_payment_setting.png";
import adManagerStep2 from "common/images/admanager_payment_profile.png";

import Styles from "../styles/signup.css";

const HTTP = /^http\:\/\/$/;

class Signup extends React.Component {
  constructor() {
    super();
    this.nameRef = React.createRef();
    this.state = {
      loading: false,
      pubName: "",
      firstName: "",
      lastName: "",
      email: "",
      countries: null
    };

    this.countryChanged = this.countryChanged.bind(this);
    this.modalClosed = this.modalClosed.bind(this);

    window.gtagPage("/signup");
    this.loadCountries();
  }

  loadCountries() {
    fetch(
      `${process.env.INTOWOW_GRAPHQL_ENDPOINT}/pubRegister/v1/geoCountries`,
      {
        credentials: "include"
      }
    )
      .then(res => {
        switch (res.status) {
          case 200:
            res.json().then(countries => {
              this.setState({
                countries: countries
              });
            });
            break;
          default:
            this.setState({
              countries: "error"
            });
            break;
        }
      })
      .catch(error => {
        this.setState({
          countries: "error"
        });
      });
  }

  countryChanged(event) {
    this.setState({
      country: event.target.value
    });
  }

  adManager360Select(is360, event) {
    this.setState({
      googleAccountType: is360 ? Constants.accountTypes.AD_MANAGER_360 : null
    });

    event.preventDefault();
  }

  adManagerSelect(isInvoiced, event) {
    this.setState({
      googleAccountType: isInvoiced
        ? Constants.accountTypes.INVOICED_AD_MANAGER
        : Constants.accountTypes.AD_MANAGER
    });

    event.preventDefault();
  }

  submit(event) {
    var httpsValues = this.state.httpsValues;
    var params = {
      pubName: this.state.pubName,
      geoCountryId: parseInt(this.state.country),
      googleAccountType: this.state.googleAccountType.id
    };

    this.setState({
      loading: true
    });

    window.gtag("event", "create", {
      event_category: "publisherForm",
      event_label: params.pubName
    });

    fetch(
      `${process.env.INTOWOW_GRAPHQL_ENDPOINT}/pubRegister/v1/createPublisher`,
      {
        method: "post",
        credentials: "include",
        body: JSON.stringify(params)
      }
    )
      .then(res => {
        if (res.ok) {
          this.props.onSignup();
        } else {
          let message;
          switch (res.status) {
            case 403:
              message = i18n`YOU_ARE_NOT_LOGGED_IN_OR_THE_ACCOUNT_DOESNT_BELONG_TO_AN_EXISTING_PUBLISHER_DOT`;
              break;
            default:
              message = res.statusText;
              break;
          }
          throw new Error(message);
        }

        this.setState({
          loading: false
        });
      })
      .catch(error => {
        this.setState({
          error: error,
          loading: false
        });
      });

    event.preventDefault();
  }

  pubNameChanged(event) {
    this.setState({
      pubName: event.target.value
    });
  }

  openImage(imageId) {
    this.setState({
      showImageId: imageId
    });
  }

  modalClosed() {
    this.setState({
      showImageId: null
    });
  }

  render() {
    var { loading } = this.state;
    var formClass = FormStyle.form;
    var errorMsg =
      this.state.error &&
      (this.state.error.message || i18n`ERROR_CREATING_PUBLISHER_ELLIPSIS`);
    var pubName = this.state.pubName;
    var countries = this.state.countries;
    var country = this.state.country;
    var googleAccountType = this.state.googleAccountType;
    var showQuestion2 =
      googleAccountType === null ||
      googleAccountType === Constants.accountTypes.AD_MANAGER ||
      googleAccountType === Constants.accountTypes.INVOICED_AD_MANAGER;
    var adManagerClass = showQuestion2
      ? CommonStyle.buttonPrimary
      : CommonStyle.button;
    var adManager360Class =
      googleAccountType === Constants.accountTypes.AD_MANAGER_360
        ? CommonStyle.buttonPrimary
        : CommonStyle.button;
    var adManagerYesClass =
      googleAccountType === Constants.accountTypes.INVOICED_AD_MANAGER
        ? CommonStyle.buttonPrimary
        : CommonStyle.button;
    var adManagerNoClass =
      googleAccountType === Constants.accountTypes.AD_MANAGER
        ? CommonStyle.buttonPrimary
        : CommonStyle.button;
    var createBtnProps = {};
    var showImageId = this.state.showImageId;

    if (!countries) {
      return (
        <div className={CommonStyle.wrapper}>
          <YbLoadingView />
        </div>
      );
    }

    if (countries === "error") {
      return <Redirect to="/" />;
      return (
        <div className={CommonStyle.wrapper}>
          <div className={`${CommonStyle.textView} ${CommonStyle.textDanger}`}>
            {i18n`ERROR_LOADING_GEO_COUNTRIES_ELLIPSIS`}
          </div>
        </div>
      );
    }

    if (!country || !pubName || !googleAccountType) {
      createBtnProps.disabled = true;
    }

    return (
      <div className={CommonStyle.wrapper}>
        <div className={CommonStyle.pageTitle}>{i18n`REGISTER`}</div>
        <div className={CommonStyle.textView}>
          {i18n`JUST_BEFORE_YOU_START_OPTIMIZING_COMMA_PLEASE_FILL_IN_TINY_REGISTRATION_FORM_DOT`}
        </div>
        {errorMsg && (
          <div
            className="alert alert-dismissible alert-danger mx-auto mt-2 w-50"
            role="alert"
          >
            <strong>{i18n`ERROR_EM`}</strong> {errorMsg}
            <button
              type="button"
              className="close"
              data-dismiss="alert"
              aria-label="Close"
            >
              <i className="fa fa-times" aria-hidden="true" />
            </button>
          </div>
        )}
        <div>
          <form onSubmit={this.submit.bind(this)} className={formClass}>
            <div className={FormStyle.row}>
              <div className={FormStyle.label}>
                {i18n`COMPANY_NAME`}{" "}
                <span className={FormStyle.requiredStar}>*</span>
              </div>
              <input
                required
                placeholder={i18n`COMPANY_NAME_ELLIPSIS`}
                onChange={this.pubNameChanged.bind(this)}
                value={pubName}
                type="text"
              />
            </div>
            <div className={FormStyle.row}>
              <div className={FormStyle.label}>
                {i18n`WHICH_TYPE_OF_GOOGLE_AD_MANAGER_ACCOUNT_ARE_YOU_USING_QM`}{" "}
                <span className={FormStyle.requiredStar}>*</span>
                <YbTooltip
                  big={true}
                  position="auto"
                  message={
                    <div>
                      <div
                        className={`${Styles.infoTitle} ${
                          CommonStyle.textAlignLeft
                        }`}
                      >{i18n`IF_UNSURE_YOU_CAN_CHECK_THE_TOP_LEFT_LOGO_OF_YOUR_GOOGLE_AD_MANAGER_CONSOLE`}</div>
                      <br />
                      <div className={CommonStyle.textAlignLeft}>
                        Ad Manager:
                      </div>
                      <span className={Styles.adManagerImage} />
                      <div className={CommonStyle.textAlignLeft}>
                        Ad Manager 360:
                      </div>
                      <span className={Styles.adManager360Image} />
                    </div>
                  }
                >
                  <span
                    className={`${CommonStyle.buttonLink} ${
                      Styles.infoTooltip
                    }`}
                  >
                    <i className="fa fa-info-circle" />
                  </span>
                </YbTooltip>
              </div>
              <button
                className={`${adManagerClass} ${Styles.adManagerBtn}`}
                onClick={this.adManager360Select.bind(this, false)}
              >
                <div className={Styles.gamLogo} />
                <span>Ad Manager</span>
              </button>
              <button
                className={`${adManager360Class} ${Styles.adManagerBtn}`}
                onClick={this.adManager360Select.bind(this, true)}
              >
                <div className={Styles.gamLogo} />
                <span>Ad Manager 360</span>
              </button>
            </div>
            {showQuestion2 && (
              <div className={FormStyle.row}>
                <div className={FormStyle.label}>
                  {i18n`DOES_YOUR_GOOGLE_AD_MANAGER_ACCOUNT_HAVE_THE_BILLING_SECTION_ON_YOUR_LEFT_PANEL_QM`}{" "}
                  <span className={FormStyle.requiredStar}>*</span>
                  <YbTooltip
                    big={true}
                    position="auto"
                    message={
                      <div>
                        <div
                          className={`${Styles.infoTitle} ${
                            CommonStyle.textAlignLeft
                          }`}
                        >{i18n`IF_UNSURE_YOU_CAN_CHECK_THE_LEFT_PANEL_OF_YOUR_GOOGLE_AD_MANAGER_CONSOLE`}</div>
                        <br />
                        <div
                          className={CommonStyle.textAlignLeft}
                        >{i18n`BILLING_SECTION_COLON`}</div>
                        <span className={Styles.billingSection} />
                      </div>
                    }
                  >
                    <span
                      className={`${CommonStyle.buttonLink} ${
                        Styles.infoTooltip
                      }`}
                    >
                      <i className="fa fa-info-circle" />
                    </span>
                  </YbTooltip>
                </div>
                <button
                  className={adManagerYesClass}
                  onClick={this.adManagerSelect.bind(this, true)}
                >{i18n`YES`}</button>
                <button
                  className={adManagerNoClass}
                  onClick={this.adManagerSelect.bind(this, false)}
                >{i18n`NO`}</button>
              </div>
            )}
            {googleAccountType && (
              <div className={FormStyle.row}>
                <div className={FormStyle.label}>
                  {i18n`WHICH_IS_YOUR_GOOGLE_ACCOUNT_MANAGER_COUNTRY_QM`}{" "}
                  <span className={FormStyle.requiredStar}>*</span>
                  <YbTooltip
                    extraBig={true}
                    position="auto"
                    message={
                      googleAccountType ===
                      Constants.accountTypes.AD_MANAGER ? (
                        <div>
                          <div
                            className={`${Styles.infoTitle} ${
                              CommonStyle.textAlignLeft
                            }`}
                          >{i18n`IF_UNSURE_YOU_CAN_CHECK_YOU_GOOGLE_ADSENSE_ACCOUNT_CONFIGURATION`}</div>
                          <br />
                          <div className={CommonStyle.textAlignLeft}>
                            {i18n`1_DOT_SIGN_IN_TO_GOOGLE_ADSENSE_CONSOLE`}
                          </div>
                          <div className={CommonStyle.textAlignLeft}>
                            <YbHighlight
                              text={i18n`2_DOT_CLICK_PAYMENTS`}
                              highlight={i18n`PAYMENTS`}
                            />
                          </div>
                          <div className={CommonStyle.textAlignLeft}>
                            <YbHighlight
                              text={i18n`3_DOT_UNDER_SETTINGS_COMMA`}
                              highlight={i18n`SETTINGS`}
                            />{" "}
                            <YbHighlight
                              text={i18n`CLICK_MANAGE_SETTINGS`}
                              highlight={i18n`MANAGE_SETTINGS_UPPERCASE`}
                            />
                          </div>
                          <span
                            className={Styles.adSenseStep1}
                            onClick={this.openImage.bind(this, 1)}
                          />
                          <div className={CommonStyle.textAlignLeft}>
                            <YbHighlight
                              text={i18n`4_DOT_UNDER_PAYMENTS_PROFILE_COMMA`}
                              highlight={i18n`PAYMENTS_PROFILE`}
                            />{" "}
                            <YbHighlight
                              text={i18n`CHECK_YOUR_COUNTRY_SLASH_REGION`}
                              highlight={i18n`COUNTRY_SLASH_REGION`}
                            />
                          </div>
                          <span
                            className={Styles.adSenseStep2}
                            onClick={this.openImage.bind(this, 2)}
                          />
                        </div>
                      ) : (
                        <div>
                          <div
                            className={`${Styles.infoTitle} ${
                              CommonStyle.textAlignLeft
                            }`}
                          >{i18n`IF_UNSURE_YOU_CAN_CHECK_GOOGLE_AD_MANAGER_ACCOUNT_CONFIGURATION`}</div>
                          <br />
                          <div className={CommonStyle.textAlignLeft}>
                            {i18n`1_DOT_SIGN_IN_TO_GOOGLE_AD_MANAGER_CONSOLE`}
                          </div>
                          <div className={CommonStyle.textAlignLeft}>
                            <YbHighlight
                              text={i18n`2_DOT_CLICK_BILLING`}
                              highlight={i18n`BILLING`}
                            />{" "}
                            >{" "}
                            <YbHighlight
                              text={i18n`PAYMENTS`}
                              highlight={i18n`PAYMENTS`}
                            />{" "}
                            >{" "}
                            <YbHighlight
                              text={i18n`PAYMENT_SETTINGS`}
                              highlight={i18n`PAYMENT_SETTINGS`}
                            />
                          </div>
                          <span
                            className={Styles.adManagerStep1}
                            onClick={this.openImage.bind(this, 3)}
                          />
                          <div className={CommonStyle.textAlignLeft}>
                            <YbHighlight
                              text={i18n`3_DOT_UNDER_PAYMENTS_PROFILE_COMMA`}
                              highlight={i18n`PAYMENTS_PROFILE`}
                            />{" "}
                            <YbHighlight
                              text={i18n`CHECK_YOUR_COUNTRY_SLASH_REGION`}
                              highlight={i18n`COUNTRY_SLASH_REGION`}
                            />
                          </div>
                          <span
                            className={Styles.adManagerStep2}
                            onClick={this.openImage.bind(this, 4)}
                          />
                        </div>
                      )
                    }
                  >
                    <span
                      className={`${CommonStyle.buttonLink} ${
                        Styles.infoTooltip
                      }`}
                    >
                      <i className="fa fa-info-circle" />
                    </span>
                  </YbTooltip>
                </div>
                <select
                  value={country || ""}
                  onChange={this.countryChanged}
                  required
                >
                  <option
                    value=""
                    disabled
                  >{i18n`SELECT_A_COUNTRY_ELLIPSIS`}</option>
                  {countries.map((country, index) => {
                    return (
                      <option key={index} value={country.id}>
                        {i18n(country.name)}
                      </option>
                    );
                  })}
                </select>
              </div>
            )}
            {!loading ? (
              <button className={FormStyle.submitBtn} {...createBtnProps}>
                <i className="fa fa-save" /> {i18n`FINISH`}
              </button>
            ) : (
              <span className={FormStyle.submitLoading}>
                <YbLoading />
              </span>
            )}
          </form>
        </div>
        <YbModal
          bigModal={true}
          show={!!showImageId}
          noSuccessBtn={true}
          cancelText={i18n`CLOSE`}
          onHide={this.modalClosed}
          title={
            showImageId < 3 ? i18n`GOOGLE_ADSENSE` : i18n`GOOGLE_AD_MANAGER`
          }
        >
          <div className={CommonStyle.textAlignCenter}>
            {showImageId === 1 && <span className={Styles.adSenseStep1} />}
            {showImageId === 2 && <span className={Styles.adSenseStep2} />}
            {showImageId === 3 && <span className={Styles.adManagerStep1} />}
            {showImageId === 4 && <span className={Styles.adManagerStep2} />}
          </div>
        </YbModal>
      </div>
    );
  }
}

export default Signup;
