import React, { useState, useEffect } from "react";
import { Card, Button, Form, FormControl } from "react-bootstrap";
import cogoToast from "cogo-toast";
import { useQuery, useMutation } from "react-apollo-hooks";

import {
  QUERY_PUBLISHERS,
  DELETE_UNREGISTERED_PUBLISHER,
} from "../query/publishers";
import {
  SUBSCRIPTION_STATUS,
  TRIAL_STATUS,
} from "../../common/constants/common";
import { UserInfoContext } from "../../common/contexts/contexts";
import CreatePublisherModal from "../components/create-publisher-modal";
import PublishersList from "../components/publishers-list";
import YbLoading from "../../../common/components/yb-loading";

function Publishers(props) {
  const [isModalShow, setIsModalShow] = useState(false);
  let { data, error, loading, refetch } = useQuery(QUERY_PUBLISHERS);
  const invitationUrl = `${window.location.origin}/#/invite/signup`;
  let publishers = null;
  const [filteredPublishers, setFilteredPublishers] = useState(publishers);

  const deleteUnregisteredPublisher = useMutation(
    DELETE_UNREGISTERED_PUBLISHER
  );

  useEffect(() => {
    setFilteredPublishers(publishers);
  }, [data]);

  function handleFilterPublishers(event) {
    let keyword = _.get(event, "target.value");
    keyword = keyword.toLowerCase();

    const pubs = _.reduce(
      publishers,
      (result, pub) => {
        if (pub.name.toLowerCase().includes(keyword)) {
          result.push(pub);
        }

        return result;
      },
      []
    );

    setFilteredPublishers(pubs);
  }

  function handleCreatePublisherClicked() {
    setIsModalShow(true);
  }

  function handleCloseModal() {
    setIsModalShow(false);
  }

  function refreshPublishers() {
    setIsModalShow(false);
    refetch();
  }

  function handleDeletePublisher(pub) {
    deleteUnregisteredPublisher({
      variables: {
        input: {
          publisherIds: [pub.publisherId],
        },
      },
    })
      .then((response) => {
        const result = _.get(
          response,
          "data.deleteUnregisteredPublisher.result"
        );

        if (result === "OK") {
          cogoToast.success(`Deleted ${pub.name}`);
          refreshPublishers();
        } else {
          throw `We are unable to successfully delete ${pub.name}`;
        }
      })
      .catch((err) => {
        cogoToast.error(
          `Something wrong happened to our server when deleting publisher ${pub.name}: ${err.message}`,
          {
            hideAfter: 15,
          }
        );
      });
  }

  if (data) {
    publishers = _.map(_.get(data, "publishers.edges"), (edge) => {
      const node = edge.node;
      const isInFreeTrial =
        _.get(node, "accountStatus.trialStatus.remainingDays") > 0;

      const trialInfo = {
        remainingDays: _.get(node, "accountStatus.trialStatus.remainingDays"),
        billableStartDate: _.get(
          node,
          "accountStatus.trialStatus.billableStartDate"
        ),
      };
      const subscriptionStatus = getSubscriptionStatus(node);

      const publisher = {
        publisherId: node.publisherId,
        name: node.name,
        isRegistered: node.registered === "REGISTERED",
        createdAt: node.createdAt,
        invitationLink: `${invitationUrl}?${node.invitationLinkQuery}`,
        isInFreeTrial,
        trialInfo: trialInfo,
        trialStatus: getTrialStatus(node),
        subscriptionStatus,
        accountType: node.accountType,
        subscriptionPlan:
          subscriptionStatus === SUBSCRIPTION_STATUS.SUBSCRIBED
            ? "Yieldbooster"
            : null,
      };

      return publisher;
    });
  }

  return (
    <React.Fragment>
      <div style={{ margin: "64px 120px" }}>
        <h1 className="display-4">Publishers</h1>
        <hr />
        <div className="d-flex justify-content-between">
          <Form>
            <FormControl
              type="text"
              placeholder="Type to search for a publisher by name"
              style={{ width: "640px" }}
              onChange={handleFilterPublishers}
            />
          </Form>
          <Button variant="primary" onClick={handleCreatePublisherClicked}>
            Create Publisher
          </Button>
        </div>

        <div style={{ marginTop: "32px" }}>
          {/* TODO: PROPER LOADING */}
          {loading && <YbLoading />}

          {error && <div>Something wrong when querying publishers</div>}

          {data.publishers ? (
            <UserInfoContext.Consumer>
              {({ userInfo }) => {
                return (
                  <PublishersList
                    userInfo={userInfo}
                    publishers={filteredPublishers}
                    handleDeletePublisher={handleDeletePublisher}
                    refetchPublishers={refreshPublishers}
                  />
                );
              }}
            </UserInfoContext.Consumer>
          ) : (
            <Card className="text-center" bg="light">
              <Card.Body style={{ padding: "64px" }}>
                <Card.Title>No publishers yet</Card.Title>
                <Card.Text>
                  Create a publisher to start inviting publishers to join your
                  optimization program.
                </Card.Text>
              </Card.Body>
            </Card>
          )}
        </div>

        <CreatePublisherModal
          isModalShow={isModalShow}
          handleCloseModal={handleCloseModal}
          refreshPublishers={refreshPublishers}
        />
      </div>
    </React.Fragment>
  );
}

function getSubscriptionStatus(publisher) {
  // SUBSCRIBED, NOT_SUBSCRIBED
  const accountType = _.get(publisher, "accountType");
  if (accountType === "ACTIVE") {
    return SUBSCRIPTION_STATUS.SUBSCRIBED;
  }

  return SUBSCRIPTION_STATUS.NOT_SUBSCRIBED;
}

function getTrialStatus(publisher) {
  // TRIAL, TRIAL_EXPIRED
  const accountType = _.get(publisher, "accountType");
  if (accountType === "TRIAL_EXPIRED") {
    return TRIAL_STATUS.TRIAL_EXPIRED;
  }

  const remainingDays = _.get(
    publisher,
    "accountStatus.trialStatus.remainingDays"
  );
  if (remainingDays > 0) {
    return TRIAL_STATUS.TRIAL;
  }
}

export default Publishers;
